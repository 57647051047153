// CSS Geral das Tables

.table {
    color: $azul-escuro;

    thead {
        tr {
            th {
                vertical-align: middle;	                				
				border-bottom: none;
				text-align: center;              

				&:first-of-type{ text-align: left; }
				&:last-of-type{ text-align: center; }	
            }
        }
    }
    
}

// Tabela de Classificação

.tabela-classificacao{	

	thead{
		font-size: 14px;     
       
		tr{
			th{
				
                padding: 0 0 19px 0;	               
                font-weight: bold;		
			}
		}
	}

	tbody{
		font-size: 14px;
		tr{
			td{
				vertical-align: middle;
				padding: 15px 0;                
				text-align: center;
                border-top: 1px solid $cinza-3;				

                @media(min-width: 768px) {                    
                    &:nth-of-type(odd) {
                        background-color: $cinza-1;
                    }
                }                            

				&:first-of-type{ width: 60px; text-align: left; background-color: transparent ; }
				&:last-of-type{ width: 40px; text-align: right; background-color: transparent ; }             

                .tabela_time-nome {
                    font-size: 14px;

                    @media(min-width: 992px) {
                        font-size: 16px;
                        margin-left: 22px;
                    }
                }
			}

			&.empatados + .empatados{
				td{ border-top: none; }
			}

            &:first-child {
                td {
                    border-radius: 8px 8px 0 0;
                }
            }

            &:last-child {
                td {
                    border-radius: 0 0 8px 8px;
                }
            }
		}

		img{ width: 28px; }

		tr:first-of-type td{
			border-top: none;
		}
	}

    &-reduzida {
        tbody {
            tr {
              
                td {
                    @media(min-width: 768px) {
                        &:nth-of-type(odd) {
                            background-color: transparent;
                        }
                    }  
                }
                
            }
        }
        
    }
}

//Tabela ranking de liga

.tabela-ranking-liga {      

    thead {
        font-size: 14px;
        font-weight: bold;
        tr {
            th {
                border: 0;

                &:first-child {
                    width: 80px;

                    @media(min-width: 992px) {
                        width: 140px;
                    }
                }
            }
        }         
    }

    tbody {
        font-size: 18px;
        font-weight: bold;

        tr {              
            &:nth-of-type(odd) {               
                background-color: $cinza-2;  
                td {
                    &:first-child {
                        border-radius: 12px 0 0 12px;
                    }
                    &:last-child {
                        border-radius: 0 12px 12px 0;
                    }
                }                       
            }         
            td {
                border: 0;

                &:last-child {
                    text-align: center;
                }
            }
        }
    }
    
    .ligas_tabela-posicao {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 14px;        
        color: #787878;
      }  

    .ouro {
        .ligas_tabela-posicao {
            background-color: #F8CA00;
            color: #F2A407;
          }
    }

    .prata {
        .ligas_tabela-posicao {
            background-color: #D8D7D7;
            color: #787878;
          }
    }

    .bronze {
        .ligas_tabela-posicao {
            background-color: #E26605;
            color: #9A470A;
          }
    }     


  }

//Tabela Resgates 

.tabela-resgates{
    font-size: 14px; 

	thead{	
       
		tr{

			th{
				
                padding: 0;   
			}
		}
	}

	tbody{
	
		tr{

			td{
				vertical-align: middle;
				padding: 15px 10px;                
				text-align: center;
                border-top: 1px solid $cinza-3;	                           

				&:first-of-type{ text-align: left;  }
				&:last-of-type{  text-align: right;  }             

			}	           
		}

		img{ max-width: 28px; }

		tr:first-of-type td{
			border-top: none;
		}        

        .info_mobile {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            @media(min-width: 768px) {
                display: none;
            }
        }

        .info_desktop {
            display: none;
            
            @media(min-width: 768px) {
                display: table-cell;
            }
        }
	}

  &--v2{

    thead {
      display: none;

      @include media-min768{
        display: table-header-group;
      }

      tr {

        .th-nome{

          @include media-min992{
            width: 50%;
          }
        }

        th {
          text-align: left;

          &:last-of-type{
            text-align: right;
          }
        }
      }
    }

    tbody {

      tr {

        td {
          padding-left: 0;
          padding-right: 0;
          text-align: left;
        }
      }
    }
  }
}

//Tabela Artilheiros

.tabela-artilheiros{	

	thead{
		font-size: 14px;
       
		tr{

			th{					
                padding: 0 0 19px 0;	               
                font-weight: bold;	
            }
		}
	}

	tbody{
		font-size: 14px;
		tr{

			td{
				vertical-align: middle;
				padding: 15px 0;                
				text-align: center;
                border-top: 1px solid $cinza-3;	                           

				&:first-of-type{ width: 60px; text-align: left;  }
				&:last-of-type{ width: 40px; text-align: right;  }             

			}

			&.empatados + .empatados{
				td{ border-top: none; }
			}

            &:first-child {
                td {
                    border-radius: 8px 8px 0 0;
                }
            }

            &:last-child {
                td {
                    border-radius: 0 0 8px 8px;
                }
            }
		}

		img{ max-width: 28px; }

		tr:first-of-type td{
			border-top: none;
		}
	}
   
}