.palpites {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .palpite {
    &_data {
      width: 100%;
      text-align: center;
      color: $azul-escuro;
      font-family: 'Poppins', sans-serif;
    }
  
    &_item {
      position: relative;
      width: 100%;
      max-width: 500px;
      padding: 16px 8px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EBEBEB;

      &.palpitado {
        .form-control {
          background-color: $azul;
          color: white;
        }
      }
    }
  
    &_time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
  
      p {
        margin: 0; 
        font-size: 18px;
        color: $azul-escuro;
      }
  
      &:nth-of-type(1){
        p {
          margin-right: 8px; 
          @include media-min992 {  margin-right: 32px; }
        }
        img {
          margin-right: 4px; 
          @include media-min992 {  margin-right: 24px; }
        }
      }
  
      &:nth-of-type(3){
        p {
          margin-left: 8px; 
          @include media-min992 {  margin-left: 32px; }
        }
        img {
          margin-left: 4px; 
          @include media-min992 {  margin-left: 24px; }
        }
      }
    }
  
    &_icone {
      margin: 0 14px;
      color: $azul-escuro;
      font-size: 24px;
      @include media-min992 { margin: 0 32px; }
    }

    &_img {
      width: 32px;
    }

    &_btn {
      margin: 50px 0;
      width: 100%;
      @include media-min768 { width: auto; }
    }

    &_legenda {
      margin-bottom: 80px;
      display: flex;
      align-items: center;

      &-item {
        margin-right: 32px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 18px;
        color: $azul-escuro;

        p { margin: 0; }

        &:last-of-type { margin-right: 0; }

        &::before {
          content: '';
          margin-right: 8px;
          width: 20px;
          height: 20px;
          background-color: #EAEAEA;
        }

        &.palpitado {
          &::before {
            background-color: $azul;
          }
        }

        &.errado {
          &::before {
            background-color: $vermelho;
          }
        }

        &.certo {
          &::before {
            background-color: $verde;
          }
        }
      }
    }

    &_barra {
      position: absolute;
      top: 0;
      left: -14px;
      width: 14px;
      height: 100%;
      background-color: #EAEAEA;
      border-bottom: 1px solid white;

      &:hover {
        .palpite_tooltip {
          @include media-min992 {
            display: flex;
            animation: tooltip-in 0.4s ease forwards;
          }
        }
      }
    }

    &_tooltip {
      padding: 4px 14px;
      position: absolute;
      top: 50%;
      left: -200px;
      display: none;
      width: 180px;
      height: 42px;
      align-items: center;
      transform: translate(-20px, -50%);
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      background-color: #EAEAEA;
      text-align: center;
      opacity: 0;
      transition: opacity 0.4s ease, transform 0.4s ease;
      transition-delay: 100ms;

      p { margin: 0; }

      &::before {
        position: absolute;
        top: 40%;
        right: -12px;
        transform: translate(-50%);
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 8.7px;
        border-color: transparent transparent transparent #EAEAEA;
      }
    }

    &-errado {
      .form-control,
      .palpite_barra,
      .palpite_tooltip {
        background-color: $vermelho;
        color: white;
      }

      .palpite_tooltip::before {
        border-color: transparent transparent transparent $vermelho;
      }
    }

    &-certo {
      .form-control,
      .palpite_barra,
      .palpite_tooltip {
        background-color: $verde;
        color: white;
      }

      .palpite_tooltip::before {
        border-color: transparent transparent transparent $verde;
      }
    }

    &-feito{
      .form-control,
      .palpite_barra,
      .palpite_tooltip {
        background-color: $azul;
        color: white;
      }

      .palpite_tooltip::before {
        border-color: transparent transparent transparent $azul;
      }
    }
  }

  &_form,
  form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@keyframes tooltip-in {
  0% {
    display: flex;
    opacity: 0;
    transform: translate(-20px, -50%);
  }

  10% {
    display: flex;
    opacity: 0;
    transform: translate(-20px, -50%);
  }

  100% {
    opacity: 1;
    transform: translate(0, -50%);
  }
}