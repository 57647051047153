/* ==========================================================================
   Formulários
   ========================================================================== */

   .form-group {
    position: relative;
    width: 100%;  
    
    .icone-spinner {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
  } 

  .input-label,
  .select-label {
      font-size: 14px;
      color: $azul-escuro;
      font-weight: 400;
  }
  
  .form-control {
    -webkit-appearance: none;       
    -moz-appearance: none;
    appearance: none;  
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 15px 16px;
    background-color: white;
    outline: none; 
    border-radius: 6px;
    border: 1px solid $cinza-5;
    font-size: 16px;
    height: 55px;
    color: $cinza-9;

    &-sm{
      height: 40px;
      padding: 8px 12px;
    }
  
    &.input-animated, &.select-animated {

        ~.input-label,
        ~.select-label{
            position: absolute;
            font-weight: bold;
            top: 0;
            left: 10px;
            z-index: 5;
            padding: 0 6px;
            background-color:white;
            color: $cinza-9;
            font-size: 12px;
            opacity: 1;
            transition: 0.3s;
            transform: translateY(-50%);
        }      
        
         ~ .select-label{
            opacity: 0;
            z-index: -1;
        }
      
        &:placeholder-shown ~ label {
          top: 50%;
          font-size: 16px;         
          color: $cinza-9;  
          font-weight: 400;
        }  
  
        &:focus ~ .input-label {
            top: 0;
            font-size: 12px;   
            opacity: 1;  
            color: $cinza-9;  
            font-weight: bold; 
            
        }
    }  

    &:disabled {
        opacity: 0.32;
        background-color: white;

        ~ .input-label {
            background-color: transparent;  
            opacity: 0.5;          
        }

        ~ .fa, ~.icones-brasileirao {
            opacity: 0.32;
        }
    }
  }
  
  .select {
    position: relative;  
    width: 100%;    
    border: 1px solid $cinza-5; 
    background-color: white;
    border-radius: 6px;   
  
    .form-control {      
             
        border: 0;                 
        z-index: 3;    
        background-color: transparent;    
  
        option {
            color: black;
        }  
       
    } 
  
    .select-changed {      
  
      & + .select-label{
        opacity: 1;
        z-index: 5;
      }
    }
  
    .icones-brasileirao, .fa {
        position: absolute;
        top: 50%;
        right: 19px;
        transform: translateY(-50%);
        font-size: 16px;
        color: $azul-escuro;
        z-index: 2;
    }
  } 
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
     box-shadow: 0 0 0 30px white inset !important;
  }
  
  input:-webkit-autofill {
    -webkit-text-fill-color: $cinza-9 !important;
  }

// Input Customizado Selecionar Amigos

.search{
	position: relative;
	width: 100%;
	max-width: 540px;

	&-input{
		display: flex;
		position: relative;
		z-index: 2;
		width: 100%;

		.form-control{
			width: 100%;
			border-radius: 6px;
			border: 1px solid #EBEBEB;
		}

		.icone{
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 62px;
			height: 100%;
			background-color: #005AFF;
			font-size: 22px;
			color: white;
			border-radius: 6px;
			cursor: pointer;
			transition: all ease 0.4s;

			&:hover{
				background-color: lighten(#005AFF, 8%);
			}
		}
	}

	&-resultado{
		display: none;
		position: absolute;
		left: 50%;
		bottom: 6px;
		z-index: 1;
		width: 98%;
        max-height: 240px;
		padding: 28px 18px;
		background-color: white;
		border: 1px solid #EBEBEB;
        overflow: auto;
		transform: translate(-50%, 100%);

		&.active{
			display: block;
		}
	}
}

  
//Input e Select Palpite
.form-control-palpite {
    padding: 0 16px;
    max-width: 63px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
}

.select-palpite {
    max-width: 63px; 
    background-color: #EAEAEA;

    .icones-brasileirao, .fa { 
        right: 10px;     
        font-size: 10px;       
      
    }    
}

// Input Check-in Local Custom
.input-radio-box{
	position: relative;

	.input{
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		opacity: 0;
	}

	.box{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 127px;
		height: 114px;
		background-color: white;
		color: black;
		border-radius: 10px;
		border: 1px solid #EBEBEB;
		cursor: pointer;
		transition: all ease 0.4s;

        &:hover {
            background-color: $verde;
            color: white;

            .icone{
                -webkit-filter: grayscale(1) invert(1);
                filter: grayscale(1) invert(1);
            }
        }
	}

	.nome{
		margin: 6px 0 0;
		font-family: 'Poppins', sans-serif;
		font-size: 16px;
	}

	.icone{
		transition: all ease 0.4s;
	}

	.input:checked + .box{
		background-color: #029C55;
		color: white;

		.icone{
			-webkit-filter: grayscale(1) invert(1);
			filter: grayscale(1) invert(1);
		}
	}
}

// Custom Checkbox

.checkbox_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;  
}

.checkbox-custom {
    display: flex;
    align-items: center;  
    line-height: 1;
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    font-size: 16px;
    color: $cinza-9;

    &_input {
      display: none;

      &:checked + .checkbox-custom_box {
          background-color: $azul;
          color: white;
      }
    }

    &_box {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 29px;
      width: 29px;
      height: 29px;
      margin-right: 11px;
      background-color: white;
      font-size: 14px;
      color: transparent;
      border: 1px solid $cinza-5;
      border-radius: 6px;
      cursor: pointer;
      transition: all ease 0.4s;
    }

    //Versão pequena
    &-small {
        .checkbox-custom_box {
            min-width: 18px;
            width: 18px;
            height: 18px;
        }

        .fa {
            font-size: 10px;
        }
    }
}  

//Custom Radio

/* Customize the label (the container) */
.radio_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: max-content;
    padding-left: 35px;
    margin-bottom: 8px;
    color: $azul-escuro;
    cursor: pointer;
    font-size: 11px;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        .checkmark {
            background-color: rgba($verde, 0.5);
        }
    }

    .input-radio {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;        

        &:checked ~ .checkmark::after {
            display: block;
        }

        &:checked ~ .input-radio_text {
            color: $verde;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #E1E1E1;
        height: 21px;
        width: 21px;
        background-color: white;
        border-radius: 50%;
        transition: 0.3s ease;

        &::after {
            content: "";
            position: absolute;
            display: none;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $verde;
        }
    }

    .input-radio_text {
        margin-top: 2px;
    }
  }   

  .visualizar-senha {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right:0;
      top:0;
      height: 100%;
      width: 55px;
      border-radius: 0 7px 7px 0;      
      outline: none;
      background-color: $cinza-2;
      border: 0;
  }
  
  

//Select 2
.form-group-select2 {
        position: relative;
        span.select2 {
            width: 100% !important;
            
            .selection {
                .select2-selection {                    
                    padding: 20px 15px; 
                    height: 55px;  
                    display: flex;
                    align-items: center;
                    border: 1px solid $cinza-5;
                    transition: .3s ease;                  
                    
                    &__placeholder {
                        color: #BABABA;   
                        font-weight: 400;                     
                    }

                    &__rendered {
                        color: $azul;
                        font-weight: 400;
                    }
                }
            }
        }
        #busca-amigo {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 70px;
            width: max-content; 
            height: 100%;
            padding: 0 10px;            
            font-size: 14px;             
            right: 0;
            top: 0;

            @media(min-width: 768px) {               
                font-size: 16px;
            }
        }
}

input.form-control-v2{
    width: 100%;
    padding: 14px;
    background-color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
	color: #686868;
    border-radius: 6px;
    border: none;
}

.erro-input{
    color: #EC2D47;
    border: 1px solid #EC2D47;

    &:focus{
        color: inherit;
    }
}