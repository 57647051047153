.container-badge {
    text-align: left;
    margin: 77px auto 67px;

    @include media-min992 {
        text-align: center;
    }

    li {
        display: inline-block;
        vertical-align: top;
        width: 240px;
        margin-left: 20px;

        button {
            width: 100%;

            @include media-min992 {
                width: 206px;
            }
        }
        
        @include media-min992 {
            width: calc(25% - 4px);
            margin-left: 0;
        }

        p {
            text-align: center;
            max-width: 210px;
            font-size: 16px;
            margin-top: 26px;
            font-family: 'Open Sans', sans-serif;
            margin-left: auto;
            color: #284164;
            margin-right: auto;
        }
    }
}

.container-divisao {
    text-align: center;
    .btn {
        margin: 38px auto 38px;
    }
}