/*!
 * Tipografia
 * Brasileirão
 * Desenvolvido por Fattoria em: 03/07/2019
 */

 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

@font-face {
    font-family: 'icones-brasileirao';
    src:  url('../fonts/icones-brasileirao.eot?stapqb');
    src:  url('../fonts/icones-brasileirao.eot?stapqb#iefix') format('embedded-opentype'),
      url('../fonts/icones-brasileirao.ttf?stapqb') format('truetype'),
      url('../fonts/icones-brasileirao.woff?stapqb') format('woff'),
      url('../fonts/icones-brasileirao.svg?stapqb#icones-brasileirao') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icones-brasileirao {
    font-family: 'icones-brasileirao' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icone-caret-down:before { content: "\e900"; }
.icone-home:before { content: "\e901"; }
.icone-pontos:before { content: "\e902"; }
.icone-arrow-left:before { content: "\e903"; }
.icone-arrow-right:before { content: "\e904"; }
.icone-facebook:before { content: "\e905"; }
.icone-instagram:before { content: "\e906"; }
.icone-twitter:before { content: "\e907"; }
.icone-youtube:before { content: "\e908"; }
.icone-map-marker:before { content: "\e909"; }
.icone-arrow-down:before { content: "\e90a"; }
.icone-check:before {content: "\e90b";}
.icone-close:before {content: "\e90c";}
.icone-lupa:before {content: "\e90d";}
.icone-ingresso:before{content: "\e90e";}
.icone-produto:before {content: "\e90f";}
.icone-circle:before {content: "\e910";}
.icone-seta-cima:before {content: "\e911";}
.icone-seta-baixo:before {content: "\e912";}
.icone-amigos-v2:before { content: "\e913"; }
.icone-marker-v2:before { content: "\e914"; }
.icone-cupom:before { content: "\e915"; }
.icone-bola:before { content: "\e916"; }
.icone-lixeira:before { content: "\e917"; }
.icone-folheto:before { content: "\e918"; }
.icone-login:before { content: "\e919"; }
.icone-seta-avancar:before { content: "\e91a"; }
.icone-seta-voltar:before { content: "\e91b"; }
.icone-olho:before { content: "\e91c"; }
.icone-galeria:before { content: "\e91d"; }
.icone-calendario:before { content: "\e91e"; }
.icone-link:before { content: "\e91f"; }
.icone-trofeu:before { content: "\e920"; }
.icone-campo:before { content: "\e921"; }
.icone-relogio:before { content: "\e922"; }
.icone-balao-fala:before { content: "\e923"; }
.icone-spinner:before { content: "\e924"; }
.icone-escudo:before { content: "\e925"; }
.icone-convidar:before { content: "\e926"; }
.icone-user:before { content: "\e927"; }
.icone-copiar:before {content: "\e928";}
