.resultado-parcial_card {
    width: 100%;
    display: flex;
    align-items: center;    

    .resultado-parcial_img-wrapper {  
        min-width: 135px;      
        width: 135px;
        height: 127px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 6px;
        border-radius: 12px;

        @media(min-width: 992px) {
            min-width: 203px;
            width: 203px;
            height: 191px;
            padding: 15px 10px 8px;
        }

        .resultado-posicao_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;
            border-radius: 6px;
            background-color: #C7FF00;
            font-weight: 700;
            font-size: 12px;
            color: #050B22;

            @media(min-width: 992px) {
                width: 39px;
                height: 39px;
                font-size: 17px;
            }
        }

        .resultado_info {
            color: white;

            .nome {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 700;
                margin-bottom: 0;              

                @media(min-width: 992px) {
                    font-size: 16px;
                }
            }

            .time {
                text-transform: uppercase;
                font-size: 8px;          
                margin-bottom: 0;               

                @media(min-width: 992px) {
                    font-size: 13px;
                }
            }
        }
    }

    .resultado-bar_holder {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        width: 100%;       
        height: 84px;
        border-radius: 0 6px 6px 0;

        @media(min-width: 992px) {
            height: 114px;
            border-radius: 0 12px 12px 0;
        }
        

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: white;
            opacity: 0.18;
        }

        .bar {
            background-color: #C7FF00;
            height: 70px;
            border-radius: 0 6px 6px 0;
            margin-right: 8px;

            @media(min-width: 992px) {
                height: 100px;
                border-radius: 0 10px 10px 0;
            }
        }

        .porcentagem {
            color: #C7FF00;
            margin-bottom: 0;
            font-size: 14px;

            @media(min-width: 992px) {
                font-size: 32px;
            }
        }
    }

    &-feminino {
        .resultado-parcial_img-wrapper {
            .resultado-posicao_wrapper {
                background-color: #2DA1EB;
            }
        }

        .resultado-bar_holder {
           .bar {
            background-color: #2DA1EB;
           }

           .porcentagem {
            color: white;
           }
        }
    }
}

.area-cards {
    display: flex;
    justify-content: space-between;
    margin: 87px auto 130px;
    max-width: 984px;

    @include media-min992 {
        margin-top: 110px;
        margin-bottom: 96px;
    }
}

.cards {
    text-align: center;
    width: calc(50% - 3.5px);

    @include media-min992 {
        max-width: initial;
        width: calc(50% - 30px);
    }

    .btn-secundaria {
        margin: 13px 0;
        font-size: 14px;
        width: 100%;

        @include media-min992 {
            margin-top: 14px;
            margin-bottom: 19px;
        }
    }



    .link-votacao {
        text-decoration: underline;
        font-size: 14px;
    }

}

.card-imagem {
    background-image: url('../img/img-selecao-b1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;


    &::after {
        content: "";
        display: block;
        padding-bottom: 84.4%;


        @include media-min992 {
            padding-bottom: 54.4%;
        }
    }

    &.feminino {
        background-image: url('../img/img-selecao-b2.png');
    }

}



.esconde {
    display: none;

    @include media-min992 {
        display: contents;
    }
}

.craque-galera_intro {
    position: relative;
    margin: 29px auto 40px;
    width: 100%;
    max-width: 301px;

    @media(min-width: 992px) {
        max-width: 461px;
        margin: 47px auto 70px;
    }

    &::after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 5px;
        bottom: 5px;
        background-color: white;
        opacity: 0.18;
        border-radius: 12px;

        @media(min-width: 992px) {
            top: 8px;
            bottom: 8px;
        }
    }

    .bar {
        margin: 0 auto;
        width: 90%;
        background-color: #C7FF00;
        height: 5px;

        @media(min-width: 992px) {
            height: 8px;
        }

        &-top {
            border-radius: 6px 6px 0 0;
        }

        &-bottom {
            border-radius: 0 0 6px 6px;
        }
    }

    .content {
        position: relative;
        z-index: 1;
        text-align: center;
        padding: 12px 0;
        font-size: 16px;

        @media(min-width: 992px) {
            padding: 19px 0;
            font-size: 26px;
        }

        .title {            
            color: #C7FF00;            
            font-weight: 700;
            margin: 0;  
            font-size: 16px;
            
            @media(min-width: 992px) {
                font-size: 26px;
            }
        }

        .texto {
            color: white;
            margin: 0;
        }
    }

    &-feminino {
        &::after {
            background-color: black;
            opacity: 0.38;
        }
        
        .bar {
            background-color: #2DA1EB;
        }

        .content {
            .title {
                color: #2DA1EB;
            }
        }
    }
}

.modal-header {
    padding: 0;
    border-bottom: 0;
    background: transparent;
    margin-bottom: 10px;

    .fechar {
        color: #B1B1B1;
        font-size: 14px;
        border: none;
        background: transparent;

        &.fechar:hover {
            opacity: 0.6;
        }

    }
}

.craque-galera_card {
    width: 100%;
    display: flex;
    align-items: center;    

    .craque-galera_img-wrapper {        
        width: 203px;
        height: 191px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        border-radius: 12px;
        margin-top: 30px;
        margin-bottom: 21px;

    }

    .craque-da-galera {
        position: absolute;
        bottom: -10px;
        left: 0;
        padding: 0 6px;
    }
}

.texto-votar {
    color: #010929;
    font-size: 22px;
    margin-bottom: 18px;
    font-weight: 300;
}

.btns-votacao {
    display: flex;
    justify-content: center;
    margin: 0 auto 50px;
    width: 100%;
    max-width: 400px;

    @include media-min992 {
        width: 400px;
    }

    .btn-votar {
        background-color: #050B22;
        color: #C7FF00;
        text-transform: uppercase;
        margin: 0;
        width: 100%;

        &:hover{
            background-color: lighten(#050B22, 8%);
        }
    }

    .btn-votar-feminino{
        background-color: #050B22;
        color: #20A8FF;
        text-transform: uppercase;
        margin: 0;
        width: 100%;

        &:hover{
            background-color: lighten(#050B22, 8%);
        }
    }

    .btn-cinza {
        width: 100%;
    }

}

.confirmacao {
    .fechar {
        text-decoration: underline;
        color: #B1B1B1;
        font-size: 18px;
        cursor: pointer;
        border: none;
        background: transparent;
        transition: 0.3s ease;

        &.fechar:hover {
            color: #23527c;
            transform: translateY(-2px);
            
        }
    }

    .btns-votacao {
        width: 314px;
        margin-bottom: 23px;
    }
    
}

.craque-da-galera_sect {   
    position: relative;
    overflow: hidden;   
    background-color: #050B22;  
    
    .flutuante {
        display: none;
        position: absolute;
        z-index: 2;
        background-color: #C7FF00;
        height: 7px;

        @media(min-width: 1300px) {
            display: block;
        }

        &-feminino {
            background-color: #2DA1EB;
        }

        &-1 {
            width: 59px;
            left: 0;
            top: 650px;
        }

        &-2 {
            width: 100px;
            left: 0;
            top: 1000px;
        }

        &-3 {
            width: 65px;
            left: 0;
            top: 1014px;
        }

        &-4 {
            width: 50px;
            right: 0;
            top: 850px;
        }

        &-5 {
            width: 75px;
            right: 0;
            top: 864px;
        }

        &-6 {
            width: 20px;
            right: 0;
            top: 1300px;
        }
    }

    .craque-da-galera_bg {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        height: 864px;
        background-image: url('../img/bg-craque-masculino-mobile.png');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;

        @media(min-width: 992px) {
            background-image: url('../img/bg-craque-masculino.png');
            height: 864px;
        }
    }

    &-feminino {
        background-color: #005177;

        .craque-da-galera_bg {
            background-image: url('../img/bg-craque-feminino-mobile.png');

            @media(min-width: 992px) {
                background-image: url('../img/bg-craque-feminino.png');
            }
        }
    }

    .content-craque-galera {
        position: relative;
        z-index: 3;

        .logo-brasileirao {
            display: block;
            margin: 30px auto 14px;
        }

        .logo {
            max-width: 287px;
            display: block;
            margin: 29px auto 0;

            @media(min-width: 992px) {
                max-width: initial;
                margin: 142px auto 0;
            }

            &-feminino {
                margin: 0 auto;
            }
        }

        .btn-azul-3da-escuro {
            display: inline-flex;
            width: 100%;
            margin: 34px 0 56px;

            @media(min-width: 768px) {
                width: initial;
                margin: 69px 0 29px;
            }
        }
    }
}

.resultado-parcial-cards_wrapper {
    .resultado-parcial_card {
        margin-bottom: 23px;

        &:last-child {
            margin-bottom: 0;
        }

        @media(min-width: 992px) {
            margin-bottom: 19px;          
        }
    }
}



.craque-da-galera_votar{
    .btn{

        &.votacao[data-toggle]{
            margin-bottom: 14px;

            @media(min-width: 768px) {
                margin-bottom: 30px;
            }
        } 
    }
}

.votar_feminino{
    .btn{

        &.votacao[data-toggle]{
            margin-bottom: 14px;

            @media(min-width: 768px) {
                margin-bottom: 30px;
            }
        } 
    }

    .btn-azul-2da-escuro {
        display: inline-flex;
        width: 100%;
        margin: 34px 0 56px;

        @media(min-width: 768px) {
            width: initial;
            margin: 69px 0 29px;
        }
    }
}

.cards-gol {
    display: flex;
    justify-content: space-between;
    max-width: 876px;
    margin: 0 auto;
    position: relative;

    .cards {
        text-align: center;
        width: calc(50% - 8px);

        @include media-min992 {
            max-width: initial;
            width: calc(50% - 55px);
        }

        .btn-azul-claro,
        .btn-secundaria {
            margin: 13px 0;
            font-size: 14px;
            width: 100%;

            @include media-min992 {
                margin-top: 14px;
                margin-bottom: 19px;
                font-size: 24px;
            }

        }

    }

    .card-gol-content {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        

        &:hover {
            .play {
                scale: 1.1;
            }
        }


        &::after {
            content: "";
            display: block;
            padding-bottom: 78.5%;


            @include media-min992 {
                padding-bottom: 62.1%;
            }
        }
    }

    .play {
        max-width: 29px;
        transition: .3s ease;
    
        @include media-min768 {
            max-width: initial;
        }
    }

    .gol-info {
        color: #ffffff;
        position: absolute;
        bottom: 0;
        margin-bottom: 11px;

        @include media-min992 {
            font-size: 20px;
        }
        
        .nome {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0;
            
            @include media-min992 {
                font-size: 17px;
            }
        }

        .clube {
            text-transform: uppercase;
            font-size: 8px;
            margin-bottom: 0;

            @include media-min992 {
                font-size: 13px;
            }
        }

        
    }

    .x-centro {
        color: #ffffff;
        font-size: 34px;
        font-weight: 700;
        left: calc(50% - 12px);
        top: calc(50% - 60px);
        position: absolute;
        text-align: center;
        z-index: 1;

        @include media-min992 {
            font-size: 54px;
            top: calc(50% - 80px);
            left: calc(50% - 16.37px);
        }
    }


}

.btn-voltar-fim {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 43px;

    @include media-min992 {
        width: 177px;
        margin-top: 34px;
        margin-bottom: 66px;
    }
}


