.badge{		
	padding: 0;
	width: 10px;
	height: 10px;
	padding-top: 4px;
	border-radius: 50%;

	&:empty {
		display: inline-block;
	}

	& + .badge{ margin-left: 5px; }

	&-verde{
		background-color: $verde;
		color: white;
	}

	&-cinza{
		background-color: #E8E8E8;
		color: $azul-escuro;
	}

	&-vermelho{
		background-color: $vermelho;
		color: white;
	}
}

.badge-v2{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 10px;
	line-height: 8px;
	border-radius: 3px;

	&.badge-vermelho{
		background-color: #EF0063;
		color: white;
	}
}

.btn {
    .badge-v2{
        position: absolute;
        top: -2px;
        right: -4px;
    }
}