

.card-noticia{
        display: flex;
        flex-direction: column;
        text-decoration: none;
        margin-top: 30px;
        font-family: 'Poppins', sans-serif;

        @include media-min768{
            flex-direction: row;
        }

        &-img{
            min-width: 240px;
            height: 220px;
            background-size: cover;
            border-radius:25px;

            @include media-min768{
                border-radius:10px;
            }
        }

        &-body{
            padding: 0;
            margin-top: 10px;

            @include media-min768{
                padding: 5px 20px 20px 20px;
                margin-top: 0;
            }

            .card-tipo{
              display: flex;
              align-items: center;
                color: $verde;
                font-size:16px;
                padding-top: 0;
                padding-bottom: 0;
                margin-top:12px;

              &::before{
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background-color: $verde;
              }
            }

            .card-titulo{
                font-weight: bold;
                font-size:18px;
                color:black; 
                margin-top:0;
            }

            .card-texto{
                font-size:14px;
                color:black; 
            }
        }
         
        &.card-noticia-normal{
            display: flex;
            flex-direction: column;
            margin-right:20px;

            &-body{
                padding: 0;
            }
        }
    } 

.card-opcoes{   
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-width: 250px;
        height: 230px;
        padding: 35px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        color: white; 

        &:hover{
            color: white;
        }

        @include media-min768{ 
            flex:1;
            min-width: 360px;
        }

        &-img{
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 17px;  
        } 
        
        .card-opcoes-titulo{
            padding-top:0 ;
            padding-bottom:0;
            line-height: 1;          
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;  

            @include media-min768{ 
                font-size: 36px;
                margin-bottom: 30px;
            }
        }


        .img-galerometro{
            width:220px;
            height: 82px;
            margin-left: -25px;
            margin-bottom: 10px;
        }
         
        &.card-opcoes-simples{
            min-width:160px;
            height: 102px;
            border-radius: 18px;
            display: flex; 
            flex: 0;
            justify-content:center;
            align-items:center;
            padding: 5px;
            
            .card-opcoes-titulo{
                margin-bottom:0;
                margin-top:0;
                font-size: 24px;
                text-align: center;
            }

            .img-galerometro{
                margin-left: 0;
                margin-top:5px;
                height:55px;
                width: 153px;
            }
        }
    }


.card-produto{  
        width: 100%;
        text-decoration: none;
        display:flex;
        flex-direction: column;
        margin-bottom:30px;

        @include media-min992{
          width: 264px;
          margin-bottom:0;
        }

        &-img{
            overflow: hidden;
            position: relative;
            width: 100%;
            height:262px;
            background-repeat: no-repeat;
            background-size: cover; 
            background-position:center;
            border-radius: 10px;

            @include media-min768{
                width: 100%;
                height: 250px;
            }

            .tag {
                position: absolute;
                left: 12px;
                bottom: 12px;
            }
        }

        &-body{
            display:flex;
            flex-direction: column;
            flex: 1;
            margin-top: 15px;
            font-family: 'Poppins', sans-serif;
            text-align: left;
            color: $azul-escuro;

            @include media-min992{text-align: center;}

            .card-titulo{
                flex: 1;
                font-size: 16px;
            }
            .card-data{
                font-size: 12px;
            }
            .card-trocar-pontos{
                font-size: 14px;
            }
        }
    }

    .card-criar-liga {        
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 13px;
        background-size: cover;
        padding: 23px 40px 40px;
        color: white;
        text-align: center;

        @media(min-width: 992px) {
            text-align: initial;
        }

        .card-intro {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            @media(min-width: 992px) {
                flex-direction: row;
                justify-content: flex-start;
            }

            &_img {
                margin-bottom: 12px;

                @media(min-width: 992px) {
                    margin-bottom: 0;
                    margin-left: 23px;
                }
            }

        }
    }


.card-votar{
        width: 100%;

        .card-content {
            position: relative;
            width: 100%;
            margin-bottom: 5px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius:12px;
            color: white;

            @include media-min768{margin-bottom: 13px;}

            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
              }

              .card-icone{
                position: absolute;
                width: 25px;
                top: 14px;
                left: 13px;

                @include media-min768{width: initial;}
              }
              .card-info{
                position: absolute;
                top: 78%;
                left: 13px;
                text-transform: uppercase;

                @include media-min480{top: 82%;}
                @include media-min768{top: 83%;}
                @include media-min992{top: 72%;}
                @include media-min1200{top: 77%;}

                .nome{
                    margin-bottom: 1px;
                    font-size: 11px;
                    font-weight: 700;
                    

                    @include media-min768{
                      font-size: 17px;
                    }
                }
                .clube{
                    margin-bottom: 0;
                    font-size: 8px;

                    @include media-min768{
                        font-size: 13px;
                      }
                }
              }
        }     
}