.modal-times { 
    &.fade {
        .modal-dialog {
            width: 100%;
            margin: 0;
            position:fixed;
            top:auto;
            right:auto;
            left:auto;
            bottom:0;      
            transform: translate3d(0, 100vh, 0);        
        } 
    }  

    &.in {
        .modal-dialog {
            transform: translate3d(0, 0, 0);
        }
    }
     
    .modal-body {
        padding: 28px;

        .btn {
            width: 100%;
        }
    }
}

.modal-video {
    .modal-dialog {
        width: 95%;
        max-width: 1200px;

        @media(min-width: 992px) {
            width: 80%;
        }
    }
    
    .modal-content {
        background-color: transparent;
        border: 0;

        .modal-header {
            .close {
                font-size: 40px;
                color: white;
            }
        }

        .modal-body {
            padding: 0;
        }
    }
}

.modal-idBinance{

    .modal-dialog{
        width: 90%;
        max-width: 700px;
        border-radius: 13px;
        box-shadow: none;
    }

    .modal-body{
        padding: 24px;
        font-family: 'Poppins', sans-serif;

        @media (min-width: 992px){
            padding: 35px 45px;
        }
    }

    .modal-content{
        border: none;
        box-shadow: none;
    }

    .btn-close{
        position: absolute;
        top: 14px;
        right: 14px;
        width: 26px;
        padding: 0;
        background-color: transparent;
        border: none;

        @media (min-width: 992px){
            top: 38px;
            right: 26px;
            width: initial;
        }
    }

    .modal-title{
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 400;
        line-height: 120%;
        color: #1F1960;

        @media (min-width: 768px){
            font-size: 28px;
        }

        @media (min-width: 992px){
            margin-bottom: 34px;
            margin-right: 80px;
            font-size: 40px;
        }
    }

    .desc{
        margin-bottom: 16px;
        font-size: 14px;

        @media (min-width: 992px){
            margin-bottom: 34px;
            margin-right: 46px;
            font-size: 16px;
        }

        .link{
            color: #1F1960;
            text-decoration-line: underline;
        }
    }

    .btn{
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 992px){
            margin-top: 34px;
        }
    }
}

.modal-binance{

    .modal-dialog{
        width: 90%;
        max-width: 700px;
        border-radius: 13px;
        box-shadow: none;
    }

    .modal-content{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #010c29;
        box-shadow: none;
        border: none;
        border-radius: 13px;
    }

    .btn-close{
        position: absolute;
        top: 14px;
        right: 14px;
        width: 26px;
        padding: 0;
        background-color: transparent;
        border: none;
        filter: brightness(0) invert(1);

        @media (min-width: 992px){
            top: 38px;
            right: 26px;
            width: initial;
        }
    }

    .modal-body{
        padding: 28px 28px 60px;
        font-family: 'Poppins', sans-serif;
        color: white;
        text-align: center;

        @media (min-width: 992px){
            font-size: 40px;
        }
    }

    .modal-title{
        margin: 8px auto 16px;
        max-width: 500px;
        font-size: 20px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.4px;

        @media (min-width: 768px){
            font-size: 28px;
            margin-bottom: 26px;
        }

        @media (min-width: 1600px){
            font-size: 40px;
        }
    }

    .desc{
        margin-bottom: 0;
        font-size: 14px;

        @media (min-width: 992px){
            margin-left: 26px;
            margin-right: 26px;
            font-size: 16px;
        }

        & + .desc{
            margin-top: 12px;

            @media (min-width: 992px){
                margin-top: 16px;
            }
        }
    }

    .btn{
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        max-width: 380px;
        text-transform: initial;

        @media (min-width: 992px){
            margin-top: 40px;
        }
    }

    &--extrato{
        .modal-title{
            max-width: 600px;
        }
    }
}