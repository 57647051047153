/* ==========================================================================
    Header
    ========================================================================== */

.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: max-content;
    z-index: 90;
    font-family: 'Poppins', sans-serif;

    .nav{

        &_lista{
            display: flex;
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
        }

        &_item{

            & + .nav_item{
                margin-left: 20px;

                @include media-min768{
                    margin-left: 45px;
                }
            }
        }

        &_texto{
            margin: 0;
        }

        &_link{
            display: block;
            text-align: right;
            line-height: 16px;
        }
    }

    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 19px 15px;
        width: 100%;
        max-width: 1330px;

        @media (min-width: 992px){
          padding: 18px 15px;
        }

        &::before,
        &::after{ content: none; }
    }

    &_topo{
        display: none;
        background-color: white;

        @media (min-width: 992px){
            display: block;
        }

        .nav{

            &_item{

                & + .nav_item{
                    margin-left: 24px;
                }
            }

            &_link{
                line-height: 18px;

                &:hover{
                    .nav_strong{
                        color: $azul-escuro;
                    }
                }
            }

            &_strong{
                display: block;
                color: $primaria;
                font-family: inherit;
                font-size: 20px;
                font-weight: 400;
                transition: all ease 0.4s;
            }

            &_span{
                color: $prata;
                font-size: 14px;
                text-transform: uppercase;
            }
        }

        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            background-color: $secundaria;
        }
    }

    &_bottom{
        background-color: $primaria;
        background-image: url('../img/footer-textura.jpg');
        color: white;

        .nav{
            font-size: 18px;

            &_link{
                text-align: left;
                color: white;
                transition: all ease 0.4s;
                white-space: nowrap;

                &:hover,
                &.active{
                    color: #C7FF00;
                }
            }
        }

        .container{
            overflow: auto;

            @media(min-width: 992px) {
                overflow: initial;
            }
        }
    }

    .btn-user{
        position: relative;

        .badge-v2{
            position: absolute;
            left: -6px;
        }

        .img{
            width: 28px;
            height: 28px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
        }

        .nome{
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            font-size: 16px;
        }
    }

    .login,
    .logado{
        margin-left: 80px;
        color: $azul-escuro;
    }

    .login{
        .btn-v2{ font-size: 16px; }
    }

    .logado{
        display: none;
        align-items: center;

        .btn-v2 .icone{ width: 28px; }
    }

    &_mobile{
        background-color: white;

        @media (min-width: 992px){
            display: none;
        }

        .container{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            background-color: $secundaria;
        }
    }

    .btn-menu{
        width: 30px;
        padding: 0;
        background-color: transparent;
        border: none;

        span{
            display: block;
            width: 100%;
            height: 3px;
            background-color: $primaria;
            border-radius: 10px;

            &:nth-of-type(2){
                margin-top: 6px;
                margin-bottom: 6px;
                width: 80%;
            }
        }
    }

    .btn-user-mobile{
        display: none;
        padding: 0;
        background-color: transparent;
        border: none;
        font-size: 30px;
        color: $primaria;
    }

    .link-login{
        display: block;
        width: 30px;
        color: $primaria;
        font-size: 26px;
    }

    .btn-menu-close{
        position: absolute;
        right: 25px;
        width: 25px;
        padding: 0;
        background-color: transparent;
        border: none;

        span{
            display: block;
            width: 100%;
            height: 3px;
            background-color: white;
            border-radius: 10px;
            transform: rotate(-45deg);

            &:last-of-type{
                margin-top: -3px;
                transform: rotate(45deg);
            }
        }
    }

    .nav_mobile{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 0;
        background-color: $azul-escuro;
        background-image: url('../img/img-textura-fundo-menu.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: scroll;
        transform: translateY(-10px);
        transition: all ease 0.8s;

        &_header{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .nav{
            margin-top: 32px;

            &_lista{
                display: flex;
                flex-direction: column;
            }

            &_item{

                & + .nav_item{
                    margin-left: 0;
                    margin-top: 20px;
                }

                .nav_texto-mobile {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    color: $secundaria;
                }
            }

            &_link{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                line-height: 1;
                background-color: transparent;
                border: 0;
                outline: none;
            }

            &_strong{
                color: white;
                font-size: 32px;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            &_nome{
                color: $secundaria;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
            }

            &_logo-nivea {
                max-width: 41px;
            }
        }

        &.user-nav{

            .nav_item{

                & + .nav_item{
                    margin-top: 35px;
                }
            }
        }
    }

    &.active{

        .main-nav{           
            height: 100vh;
            padding-top: 18px;
            transform: translateY(0);
            
        }
    }

    &.user{

        .user-nav{
            height: 100vh;
            padding-top: 18px;
            transform: translateY(0);
        }
    }

    &.header-logado{

        .link-login,
        .login{
            display: none;
        }

        .logado{
            display: flex;
        }

        .btn-user-mobile{
            display: block;
        }
    }

    .nav_mobile.user-nav{
        .nav_link{
            flex-direction: row;
        }
        .nav_strong{
            margin-bottom: 0;
        }
        .tag{
            margin-left: 12px;
        }
    }
}

.dropdown{
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    width: 315px;
    border-radius: 12px;
    transform: translateY(100%);

    &_wrapper{
        position: relative;

        &--right{

            .dropdown{
                left: initial;
                right: 0;
            }
        }
    }

    &_header{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 16px 20px;
        color: $azul-escuro;

        .link-underline{
            font-size: 14px;
        }
    }

    &_titulo{
        margin-bottom: 0;
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
    }

    &_body{
        padding: 16px 20px;
        background-color: $azul-escuro;
        color: white;
        font-size: 14px;
        border-radius: 12px;
        background-image: url('../img/img-textura-fundo-menu.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .login_extra{
        margin-top: 20px;
        padding-bottom: 30px;
        font-size: 14px;

        &_texto{
            position: relative;
            text-align: center;

            &::after,
            &::before{
                content: '';
                position: absolute;
                top: 50%;
                display: block;
                height: 1px;
                width: 65px;
                background-color: $azul-alt;
                transform: translateY(-50%);
            }

            &::after{
                right: 0;
            }

            &::before{
                left: 0;
            }
        }

        .btn-holder{
            display: flex;

            .btn-v2{
                flex: 1;

                & + .btn-v2{
                    margin-left: 12px;
                }
            }
        }
    }

    .link{
        transition: all ease 0.4s;

        &:hover{
            color: inherit;
            opacity: 0.7;
        }

        &-secundario{
            color: $secundaria;
        }
    }

    .btn-apoio{
        margin-top: 4px;
        padding: 0;
        color: $azul-escuro;
        background-color: transparent;
        border: none;
    }

    .btn-close{
        position: relative;
        width: 16px;
        height: 16px;
        border: 2px solid $azul-escuro;
        border-radius: 2px;

        &::before,
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 80%;
            height: 2px;
            background-color: $azul-escuro;
            border-radius: 2px;
        }

        &::before{
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &::after{
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }

    .lista-overflow{
        list-style: none;
        margin: 0 -20px -16px;
        max-height: 360px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 16px;
        overflow: auto;

        .lista-item{

            & + .lista-item{ margin-top: 20px; }
        }

        &::-webkit-scrollbar {
            width: 7px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0,0,0, 0.27);
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(0,0,0, 0.7);
        }
    }

    .notificacao-item{
        display: flex;

        .img{
            width: 50px;
            height: 50px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
        }

        .info{
            flex: 1;
            margin-left: 10px;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;

            .tempo,
            .dia{
                font-size: 12px;
                font-weight: 700;
            }
	        .tempo{ color: $secundaria; }
            .dia{ color: $cinza-6; }
        }
    }

    &_time{
        display: flex;
        align-items: center;
        font-size: 14px;

        p{ margin: 0; }

        .img{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            width: 30px;
            height: 30px;
            padding: 4px;
            border: 2px solid $cinza-4;
            border-radius: 50%;
        }
    }

    &_pontos{
        margin: 8px 0 2px;
        font-size: 16px;
        font-weight: 700;
        line-height: 10px;
        color: $azul;
    }

    .lista-nav{
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        font-size: 16px;

        .lista-link{
            display: flex;
            align-items: center;
            transition: all ease 0.4s;

            &:hover{
                color: white;
                opacity: 0.7;
            }

            .icone{
                max-width: 20px;
                margin-left: 5px;
            }

            .tag{
                margin-left: 12px;
            }
        }

        .lista-item{

            & + .lista-item{
                margin-top: 10px;
            }

            &.lista-item--destaque{
                margin-top: 18px;
                padding-top: 12px;
                color: $secundaria;
                border-top: 1px solid $azul-alt;

                .lista-link:hover{
                    color: $secundaria;
                }
            }
        }

    }
}

.dropdown_notificacoes {
    .dropdown {
        bottom: -20px;
    }
}

.dropdown_times {
    display: none;
    
    @media(min-width: 992px) {
        display: block;
    }

   .dropdown {
    bottom: -20px;
    width: 208px;

    .dropdown_body {
        background-color: white;
        background-image: initial;
        padding-top: 28px;
        padding-bottom: 28px;
     
    }
   
   }

   .btn-dropdown {
       min-height: initial;
   }
}

.times_wrapper {
    display: flex;            
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 22px;
    margin-bottom: 0;

    .times {
        width: calc(25% - 15px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}