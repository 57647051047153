.titulo{
	margin-top: 0;
	margin-bottom: 0;	
	font-size: 40px;	
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	color: $azul-escuro;

	&.h1 {
		font-size: 32px;

		@media(min-width: 992px) {
			font-size: 40px;
		}
	}

	&.h2 {
		font-size: 24px;

		@media(min-width: 992px) {
			font-size: 30px;
		}
	}

	&.h3 {
		font-size: 20px;

		@media(min-width: 992px) {
			font-size: 20px;
		}
	}

	&.titulo-branco{
		color: white;
	}

}



