.sessao-time-coracao-badge{
    text-align: right;
    position: relative;
    z-index: 2;

    figure{
        position: relative;
        text-align: center;
        display: inline-block;
        margin-top: -40px;
        z-index: 6;
        img{ max-width: 90px; }
        figcaption{
            font-family: 'Poppins', sans-serif;
            color: $azul-escuro;
            font-size: 11px;
        }
        @include media-min992{
            position: absolute;
            right: 0;
            top: 0;
            margin-right: 15px;
        }
    }
}

.meus-badges {
    margin-bottom: 73px;
    
    @include media-min992{
        margin-bottom: 73px;
    }

    ul {
        margin-top: 60px;
        width: 100%;
        
        @include media-min992 {
            margin-top: 71px;
        }

        li {
            display: inline-block;
            width: 260px;
            margin-left: 15px;
            text-align: center;
            
            &:first-of-type{
                margin-left: 0;
            }
            
            @include media-min992 {
                width: calc(25% - 15px);
                margin-bottom: 120px;
                max-width: inherit;

                &:nth-of-type(4n + 5) {
                    margin-left: 0;
                }
            }

            p {
                

                @include media-min992 {
                    max-width: 211px;
                    color: #284164;
                    font-size: 16px;
                    margin-top: 27px;
                    font-family: 'Poppins', sans-serif;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

.titulo-stats-badge {
    margin-top: 35px;
}

.lista-estatisticas {
   display: flex;
   flex-wrap: wrap;
   align-items: flex-start;
   column-gap: 20px;
   row-gap: 20px;
   margin-top: 30px;
    
    .estatistica-individual {        
        
        min-width: 322px;      
     
        
        @include media-min992 {
            width: calc(33% - 20px);
            min-width: inherit;
        }
    }
}

.sect-extrato {
    margin-bottom: 145px;
}