/* ==========================================================================
   Botoes
   ========================================================================== */

.btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10.5px 25px;
    width: max-content;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 16px;
    min-height: 45px;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    outline: none;
    transition: all ease 0.4s;

    &-lg {
        padding: 15.5px 25px;
        min-height: 55px;
    }
    
    span{       

        &.icones-brasileirao { 
            display: inline-block;
            vertical-align: middle;
            transition: none;          
            font-size: 10px;
        }

        &:first-child {
            &.icones-brasileirao, &.fa-facebook-square {
                margin-right: 12px;                
            }

           img {
            margin-right: 12px; 
           }

            &.fa-facebook-square {
                margin-top: -2px;
            }
        }

        &:last-child {
            &.icones-brasileirao, &.fa-facebook-square {
                margin-left: 12px;                
            }

            img {
                margin-left: 12px; 
            }           

            &.fa-facebook-square {
                margin-top: -2px;
            }
        }       
    }

    .icone{
        width: 20px;
    }

    .icone-font,
    .icone{
        margin-left: 8px;
    }

    &.full-width{
        width: 100%;
    }

    &.full-width-md{
        width: 100%;

        @include media-min768{ width: max-content;}
    }

    &.btn-transparent{
        padding: 0;
        background-color: transparent;
        color: inherit;
    }

    &.btn-azul{
        background-color: $azul;
        color: white;

        &:hover{
            background-color: lighten($azul, 8%);
        }
    }

    &.btn-azul-escuro{
        background-color: $azul-escuro;
        color: white;

        &:hover{
            background-color: lighten($azul-escuro, 8%);
        }
    }

    &.btn-azul-alt{
        background-color: $azul-alt;
        color: white;

        &:hover{
            background-color: lighten($azul-alt, 8%);
        }
    }

    &.btn-vermelho{
        background-color: $vermelho;
        color: white;

        &:hover{
            background-color: lighten($vermelho, 8%);
        }
    }

    &.btn-vermelho-escuro{
        background-color: $vermelho-escuro;
        color: white;

        &:hover{
            background-color: lighten($vermelho-escuro, 8%);
        }
    }

    &.btn-verde{
        background-color: $verde;
        color: white;

        &:hover{
            background-color: lighten($verde, 8%);
        }
    }

    &.btn-amarelo{
        background-color: $amarelo;
        color: $azul-escuro;

        &:hover{
            background-color: lighten($amarelo, 8%);
        }
    }  
    
    &.btn-cinza {
        background-color: $cinza-8;
        color: white;

        &:hover{
            background-color: lighten($cinza-8, 8%);
        }
    }



    &.btn-secundaria {
        background-color: $secundaria;
        color: #050B22;
        text-transform: uppercase;
        font-weight: bold;

        &:hover{
            background-color: lighten($secundaria, 10%);
        }
        &.full-width{
            width: 100%;
        }
    }


    &.btn-azul-claro {
        background-color: $azul-claro-100;
        color: #050B22;
        text-transform: uppercase;
        font-weight: bold;

        &:hover{
            background-color: lighten($azul-claro-100, 10%);
        }
        &.full-width{
            width: 100%;
        }
    }

    &.btn-azul-2da {
        background-color: $azul-2da;
        color: #0C2937;
        text-transform: uppercase;
        font-weight: bold;

        &:hover{
            background-color: lighten($azul-2da, 8%);
        }
    }

    &.btn-azul-2da-escuro {
        background-color: $azul-2da-escuro;
        color: $azul-2da;
        text-transform: uppercase;
        font-weight: bold;

        &:hover{
            background-color: lighten($azul-2da-escuro, 8%);
        }
    }
    &.btn-azul-3da-escuro {
        background-color: $azul-3da-escuro;
        color: white;
        text-transform: uppercase;
        font-weight: bold;

        &:hover{
            background-color: lighten($azul-3da-escuro, 8%);
        }
    }

    &.btn-cinza-nft {
        background-color: #BEBEBE;
        color: #7C7C7C;
        text-transform: uppercase;
        font-weight: bold;

        &:hover{
            background-color: lighten(#BEBEBE, 8%);
        }
    }



    &.btn-google{
        background-color: white;
        font-weight: 400;
        color: #5B5B5B;

        &:hover{
            background-color: darken(white, 8%);
        }
    }     

    &:disabled{
        background-color: #D9D9D9;
        color: #8F8F8F;
        cursor: not-allowed;

        &:hover{
            background-color: #D9D9D9;
            color: #8F8F8F;
        }
    }
    
}