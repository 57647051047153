
// Principais
$azul: #005AFF;
$azul-escuro: #1F1960;
$azul-alt: #006EC8;
$vermelho: #EC2D47;
$vermelho-escuro: #AE0100;
$verde: #00A255;
$amarelo: #FFC237;
$primaria: #14285A;
$secundaria: #C7FF00;
$azul-2da: #2DA1EB;
$azul-claro-100: #9ED9FF;
$azul-2da-escuro: #0C2937;
$azul-3da-escuro: #0A194B;

// Ranking
$ouro: #F8CA00;
$ouro-texto: #F2A407;
$prata: #CECECE;
$prata-texto: #787878;
$bronze: #E26605;
$bronze-texto: #9A470A;

// Cinzas
$cinza-1: #F3F3F3;
$cinza-2: #EEEEEE;
$cinza-3: #E7E7E7;
$cinza-4: #E1E1E1;
$cinza-5: #D9D9D9;
$cinza-6: #C1C1C1;
$cinza-7: #AAAAAA;
$cinza-8: #8F8F8F;
$cinza-9: #686868;
$cinza-10: #4E4E4E;

//Font-size
$fs-8: 0.6rem;
$fs-10: 0.65rem;
$fs-11: 0.7rem;
$fs-12: 0.75rem;
$fs-13: 0.8rem;
$fs-14: 0.875rem;
$fs-15: 0.9375rem;
$fs-16: 1rem;
$fs-17: 1.065rem;
$fs-18: 1.15rem;
$fs-20: 1.25rem;
$fs-22: 1.4rem;
$fs-24: 1.5rem;
$fs-25: 1.55rem;
$fs-26: 1.65rem;
$fs-28: 1.75rem;
$fs-30: 1.9rem;
$fs-32: 2rem;
$fs-34: 2.125rem;
$fs-38: 2.375rem;
$fs-40: 2.5rem;
$fs-42: 2.65rem;
$fs-44: 2.75rem;
$fs-48: 3rem;
$fs-52: 3.25rem;
$fs-56: 3.5rem;
$fs-60: 3.75rem;
$fs-62: 3.9rem;
$fs-70: 4.4rem;
$fs-80: 5rem;
