/* ==========================================================================
   Reset
   ========================================================================== */



* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body{
  font-size: 16px;
  min-height:100%;
  @include media-max767{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

a{
  color: inherit;
  &:hover,
  &:active,
  &:focus,
  &:focus:hover { transition-duration: 0.1s; }
  &:hover { text-decoration: inherit; }
}

img, iframe, object, video {
  max-width: 100%;
  width: auto;
  height: auto;
}

form {
  &:after,
  &:before {
    display: table;
    content: " ";
  }
  &:after { clear: both; }
}

textarea {
  max-width: 100%;
  width: 100%;
}


.reset-border {
  border: none !important;
}

