.jogo{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: max-content;
	padding: 16px 10px;
	background-color: white;
	font-family: 'Open Sans', sans-serif;
	color: #001E48;
	font-size: 14px;
	border-radius: 13px;
	border: 1px solid #EBEBEB;	

    .participantes{   
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;   

        &_body{
            margin-top: 12px;
            display: flex;
            align-items: center;
        }
        .usuario {
            width: 20px;
            height: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
        }
    } 

	.resultado{
		display: flex;
		align-items: center;
		margin-bottom: 12px;
		padding-bottom: 10px;
		border-bottom: 1px solid #E9E9E9;

        .info-central {
            text-align: center;
            margin: 0 22px;
            min-width: max-content;            

            .info_horario {
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 8px;
            }
        }
	}

	.time{
		display: flex;
		align-items: center;

		.time_img_wrapper{
			width: 40px;
			height: 40px;
			padding: 1px;
			border-radius: 50%;

			.time_img{
                width: 100%;
                max-height: 100%;
            }

            + .time_nome {
                margin-right: 0;
                margin-left: 10px;
            }
		}    
        
        .time_nome {
            margin-right: 10px;
            margin-bottom: 0;
        }
	
	}

	.gols{
		margin-left: 15px;
		margin-right: 15px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 20px;            

        .fa-times {
            margin: 0 7px;
            font-size: 11px;
        }

        .select{
            min-width: 70px;
        }
	}

    //JOGO SIMPLES
    &-simples {        
        background-color: transparent;
        border: 0;
        padding: 0;

        .time {
            .time_img_wrapper{
                width: 28px;
                height: 28px;              
            }    
        }

        .resultado {
            border:0;
            padding-bottom: 0;
            margin-bottom: 0;  
        }   
        
        .gols {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 40px;
            border-radius: 5px;
            background-color: $cinza-1;
            padding: 0 5px;   
            margin: 0 4px;    
            
            @media(min-width: 500px) {
                margin: 0 9px;   
            }
        }     
    }

    //JOGO DESAFIO
    &-desafio {       
        padding: 46px 20px;

        .resultado {
            border:0;           
            
            .time_nome {
                text-transform: uppercase;
                font-size: 18px;
                display: none;

                @media(min-width: 992px) {
                    display: block;
                }
            }

            .gols {
                .fa-times {
                    font-size: 20px;
                    margin: 0 16px;
                }

                .form-group {
                    margin-bottom: 0;

                    .select {

                        .dropdown-toggle {
                            padding-left: 21px;
                        }

                        .valor {
                            font-size: 20px;
                            font-weight: bold;
                        }
                        .caret {
                            top: 50%;
                            transform: translateY(-50%);
                            right: 8px;
                        }
                    }
                }
            }
        } 

        .qntd-pontos{           
            margin-top: 16px;
            margin-bottom: 0;         
        
            button.btn{
                font-size: 14px;
                padding: 0 20px;
            }
        
            input.qntd-pontos_input{
                margin-left: 6px;
                margin-right: 6px;
                max-width: 110px;
                height: 55px;             
                font-size: 14px;
                color: $azul-escuro;
                border: 1px solid #EBEBEB;
                border-radius: 10px;
        
                &:focus,
                &:hover{
                    border-color: white;
                }
            }
        }
    }

    // JOGO CHECK-IN

    &-checkin {        
        padding: 25px 27px;

        .resultado {
            border: 0;
            padding-bottom: 0;
            margin-bottom: 22px;

            .info-central {
                display: none;

                 @media(min-width: 992px) {
                     display: block;
                }
            }

            .time {
               flex-direction: row;
               align-items: center;
               justify-content: center;

               @media(min-width: 992px) {
                   flex-direction: column;
               }

               .time_nome {                   
                   font-family: "Poppins", sans-serif;                    
               }
            }          
          
        }

        .porcentagem{
            display: flex;   
            width: calc(100% - 20px);    
    
            .time{
                display: flex;
                align-items: center;
                transition: all ease 0.3s;
    
                .img{
                    height: 20px;
                    transform: translateX(-50%);                 
                }
    
                p{ margin: 0; }
    
                &_value{
                    font-family: 'Poppins', sans-serif;
                    font-size: 11px;
                    line-height: 12px;
                    color: white;
                }
            }
    
            .lado-dir{
                flex-direction: row-reverse;
    
                .img{ transform: translateX(50%) }
            }
        }

        &-reduzido {
            .resultado {
                .time {                    
                    flex-direction: row;
                }
            }
          
        }
    }
}

// JOGO ESPECIAL DA HOME
.partida{
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 8px;
    border-radius: 8px;

    &_img{
        width: 100%;

        &_holder{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            padding: 4px;
            border-radius: 50%;
        }
    }

    &_resultado{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        padding: 6px 10px;
        background-color: #F3F3F3;
        color: #001E48;
        font-size: 20px;
        font-weight: 700;
        border-radius: 5px;

        p{
            margin-bottom: 0;
        }

        .icone{
            margin-left: 10px;
            margin-right: 10px;
            font-size: 16px;
        }
    }
}