.ligas {

  &_lista {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 64px !important;   
    @include media-min992 { margin-bottom: 40px !important; }

    li{
      margin-bottom: 16px;
      @include media-min992 { margin-right: 16px; }
    }
   
  }

  &_item {
    padding-top: 24px;
    text-align: center;
    width: 249px;
    min-width: 249px;
    min-height: 322px;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.10);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.10);
    color: #878787;
    transition: all 0.4s ease;

    &:hover,
    &:focus {
      text-decoration: none;
      color: #878787;
      -webkit-box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
      transition: all 0.4s ease;
    }
  }

  &_posicao {
    margin: auto 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px 18px;
    border-top: 1px solid #F1F1F1;
    color: $azul-escuro;
    font-family: 'Poppins', sans-serif;;
    font-weight: 700;

    .icones-brasileirao {
      margin-right: 7px;
    }

    .icone-circle {
      color: #2C6BFC;
    }

    .icone-seta-cima {
      color: #00A255;
    }

    .icone-seta-baixo {
      color: #EF0063;
    }
  }

  &_icone {
    margin: 0 auto 16px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .liga-escudo{
      max-height: 100%;
    }
  }


  &_titulo {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: bold;
  }

  &_data {
    margin-bottom: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
  }

  &_conteudo { padding: 0 18px; }

  &-detalhe {
    margin-bottom: 64px;
    &_icone {
      margin: -60px auto 12px;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;

      @include media-min992 {
        margin: -75px auto 0;
        width: 150px;
        height: 150px;
      }

      img {
        max-width: 80px;
        max-height: 80px;
        @include media-min992 {
          max-width: 100px;
          max-height: 100px;
        }
      }
    }
  }  

  &_chamada {
    padding: 32px 0;
    background-image: url('../img/bg-banner-ligas.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: white;
    text-align: center;

    @include media-min992 {
      padding: 72px 0 60px;
    }

    p {
      margin-left: auto;
      margin-right: auto;
      max-width: 380px;
    }
  }
}

textarea.liga-desc{
  height: 85px;
  max-height: 85px;

  & + p{
    margin-top: 5px;
    font-size: 14px;
    color: #BABABA;
  }
}

.convidar_chamada{
  display: flex;
  height: 240px; 

  &_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    padding: 0 30px;
    background-color: $azul-escuro;

    .titulo{
      max-width: 457px;
    }
  }

  &_img{
    display: none;
    position: relative;

    @include media-min992{
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
      background-image: url('../img/bg-chamada-convidar.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &_floater{
      position: absolute;
      left: -50px;
      bottom: 0;
      max-width: initial;
    }
  }
}

.intro-ligas_wrapper {
  display: flex;  
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; 

}

.palpite-ligas_section {
  background-color: #F3F3F3;
  padding: 100px 0;
}

.criar-liga-escudo{

  .liga-escudo{
    max-width: 147px;
  }
}