.box-simples {
    background-color: white;
    border-radius: 13px;
    border: 1px solid #EBEBEB;
    padding: 32px;

    &-lg{
        padding: 40px;
    }

    & + .box-simples{
        margin-top: 24px;
    }

    &.box-time-status {
        font-size: 14px;
        color: black;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        min-width: max-content;
        width: max-content;

        @media(min-width: 992px) {
            font-size: 20px;
        }

        .icones-brasileirao {
            margin-right: 8px;

            font-size: 40px;

            @media(min-width: 992px) {
                font-size: 60px;
            }
        }

        .big-info {
            font-size: 40px;
            line-height: 1;

            @media(min-width: 992px) {
                font-size: 60px;
            }
        }
    }

    &.sucesso{
        text-align: center;
    }

    &.grafico{

    }
}

.grafico-votacao{

    .barra-votacao{
        position: relative;
        width: calc(100% - 46px);
        height: 35px;
        background-color: #C2C0C8;
        border-radius: 4px;

        &_preenchido{
            height: 100%;
            background-color: #001E48;
            border-radius: 4px;
        }

        &_legenda{
            position: absolute;
            top: 50%;
            left: 11px;
            margin-bottom: 0;
            max-width: calc(100% - 20px);
            font-family: 'Poppins', sans-serif;
            font-size: 11px;
            color: white;
            transform: translateY(-50%);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &_porcentagem{
            position: absolute;
            top: 50%;
            right: -10px;
            margin-bottom: 0;
            width: 36px;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 700;
            color: #14285A;
            transform: translate(100%, -50%);
        }

        & + .barra-votacao{
            margin-top: 5px;
        }
    }
}
