/* ==========================================================================
   Banners
   ========================================================================== */

.banner{
  position: relative;
  margin-top: 136px;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  @include media-min992{
    margin-top: 144px;
    height: 500px;
  }

  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    color: white;
  }

  .box-info{
    max-width: 425px;
    padding: 35px 30px;
    background-color: rgba(0,0,0, 0.6);
    border-radius: 13px;

    .titulo {
      word-break: break-word;
    }
  }

  .logo-titulo{
    display: flex;
    align-items: center;
  }

  &-legenda {
    position: absolute;
    left: 16px;
    bottom: 16px;
    margin: 0;
    font-size: 12px;
    color: white;
  }

  // Variantes

  &.banner-to-no-jogo{
    background-color: $azul-escuro;
    height: auto;
    padding: 50px 0;
    background-image: url('../img/img-banner-to-no-jogo.jpg');
    text-align: center;

    @media(min-width: 992px) {
      margin-top: 144px;
      height: 500px;
      padding: 0;
    }
  }

  &.banner-desafio-palpite{
    background-color: $vermelho-escuro;
    background-image: url('../img/img-banner-desafio-palpite.jpg');
  }

  &.banner--sm{
    height: 200px;

    @include media-min992{
      height: 250px;
    }
  }

  &.banner-time{
    height: auto;
    padding: 30px 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media-min992{
      height: 300px;
    }  

    .container {
      display: flex;
      align-items: center;
      flex-direction: column;

      @include media-min992{       
        flex-direction: row;
      }  

      .left-side {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;         

        @include media-min992{       
          text-align: initial;
          flex-direction: row;
          align-items: flex-start;
          max-width: 70%;
          margin-right: 50px;
        }  

        .banner-time_img {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 67px;
          width: 67px;
          height: 67px;
          padding: 12px;
          border-radius: 50%;  
          background-color: white; 
          margin-bottom: 17px;           
          
          @include media-min992 {
            margin-right: 14px; 
            margin-bottom: 0;
          }

          img{
            width: 100%;
            max-height: 100%;
          }
        }

        .left-side_content {
          margin-top: 12px;

          .titulo {
            margin-bottom: 20px;
          }
          .content_info {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;

            @include media-min992 {
              flex-direction: row;
            }

            &_box {
              display: flex;
              align-items: center;
              margin-bottom:20px;
              font-size: 14px;

              @include media-min992 {
                margin-bottom: 0;
                margin-right: 40px;               
              }                  
              
              &:last-child {                
                margin-bottom: 0;

                @include media-min992 {
                  margin-right: 0;
                }
              }

              .icones-brasileirao {     
                margin-right: 4px;
                font-size: 18px;
               
              }
            }
          }
        }
      }
    }
  }

  &.banner-ligas{
    margin: 20px 25px 40px;
    border-radius: 17px;
    background-color: rgba(0, 0, 0, 0.472);
    background-blend-mode: color-burn;
  
    @include media-min768{
     margin: 0 ;
     border-radius: 0;
    }
  }
}


.banner-brahma-palpite{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;  
  padding: 30px;
  background-image: url("../img/bg-palpite-brahma.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 

  @include media-min992{
    padding: 0 60px;
    height: 370px;
    border-radius: 18px;
  }

  @include media-min1200{
    margin-top: 100px;
    margin-bottom: 80px;
  }

  .info{
    color: white;

    @include media-min1200{
      max-width: 420px;
    }

    .logo{
      margin-bottom: 20px;
      max-width: 255px;
    }

    .titulo{ margin-bottom: 5px; color: white; }

    .texto{
      margin: 0;
      font-size: 15px;
      font-family: 'Poppins', sans-serif;
    }

    .btn{
      margin-top: 25px;
      color: #9E1817;

      @include media-min992{
        max-width: 240px;
      }
    }
  }

  .img{
    display: none;

    @include media-min1200{
      position: absolute;
      bottom: 0;
      right: 60px;
      display: block;
      height: 420px;
      overflow: hidden;
    }
  }
}

//Banner anúncio rotativo
.banner-anuncio-rotativo {
  display: block;
  width: 100% !important;  
  border-radius: 13px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  &::after {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }
}
         
.banner-ligas-conteudo{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   margin-left: 0;

   @include media-min768{
    flex-direction: row;
    margin-left: 120px;
   }

   &-img{
     height: 120px;
     margin-bottom: 0;

     @include media-min768{
      height: auto;
       margin-bottom: 90px;
     }
   }
   &-info{
     width: 80%;
     text-align: center;
     margin-left: 0;
     padding: 0;
     
     @include media-min768{
      width: 50%;
      text-align: left;
      margin-left: 30px;
    }

    .titulo-ligas{
      width: 100%;
      text-transform: none;
      margin-top: 15px;
      font-size: 24px;
      letter-spacing: 0.02rem;
      
      @include media-min768{
        width: 60%;
        font-size:40px;
      }
    }
   }
}

.banner-resgate-binance { 
  background-color: #091743; 
  height: 589px; 
  margin-bottom: 40px;

  @media(min-width: 1200px) {
    height: 509px;   
  }

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1330px;

    @media(max-width: 991px) {
      max-width: initial;
      padding: 0;
    }

    .banner_content {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 26px;
      height: 100%;

      @media(min-width: 1200px) {
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: space-between;     
      }

      .logo-ponto {
        @media(min-width: 1200px){
          display: none;
        }
      }

      .image {   
        display: block;    
        width: 100%;     

        @media(min-width: 1200px){
          max-width: 500px;
          margin: 0;
        }

        @media(min-width: 1366px){
          width: initial;
          max-width: initial;
        }

        &.desktop {
          display: none;

          @media(min-width: 1200px){
            display: block;
          }
        }

        &.mobile {      

          @media(min-width: 1200px){
            display: none;
          }
        }
      }

      .content {
        width: 100%;
        padding: 0 16px 47px;

        @media(min-width: 1200px) {
          width: 50%;
          max-width: 603px;
          min-width: 603px;
          padding: 56px 0 0 0;         
        }

        .form_box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px 15px 34px;
          border-radius: 5px;
          background-color: #223476;          

          @media(min-width: 1200px) {
            padding: 34px 32px 40px;
            margin: 0;
          }

          .form_label {
            display: flex;
            align-items: center;
            gap: 9px;
            font-family: 'poppins', sans-serif;
            font-size: 16px;      
            font-weight: 500;
            line-height: normal;
            margin-bottom: 8px;
            color: white;
          }

          .form_holder {
            display: flex;
            flex-direction: column;             
            gap: 16px;    
            
            @media(min-width: 1200px) {
              align-items: flex-end; 
              flex-direction: row;
            }

            .btn {
              display: flex;
              align-items: center;
              gap: 10px;
              text-transform: initial;
              width: 100%;
              height: 55px;
              font-weight: 500;

              @media(min-width: 1200px) {
                max-width: 205px;
              }

              &:disabled {
                background-color: $secundaria;
                color: #131428;
                opacity: 1;
                cursor: default;
              }
            }

            .form-control {
              &:disabled {
                opacity: 1;
                border: 1px solid #DDD;
                background: #BFBFBF;
                color: #848484;
              }
            }
          }
        }
      }      
    }

    @keyframes slide-fade-in {
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .flutuante {
      position: absolute;
      top: 275px;
      left: -30px;
      max-width: 90px;

      @media(min-width: 1200px) {
        top: initial;
        left: initial;
        right: 0;
        bottom: -70px;
        max-width: initial;
        opacity: 0;
        transform: translateX(100px);
        animation: slide-fade-in 1s forwards 1s ease;
      }
    }

    .title, .texto {
      display: none;
      color: white;
      line-height: normal;
      font-family: 'Poppins', sans-serif;

      @media(min-width: 1200px) {
        display: block;
      }
    }

    .title {    
      font-size: 56px;    
      font-weight: 700;
      line-height: normal;
      margin-bottom: 16px;      
      margin-top: 0;
    }

    .texto {      
      font-size: 16px;
      margin-bottom: 27px;
    } 
  }

  [data-toggle="modal"]{
    cursor: pointer;
  }
}