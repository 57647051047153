/* ==========================================================================
   Geral
   ========================================================================== */

body{
	font-family: 'Poppins', sans-serif;
	color: #666666;
	background-color: #fafafa;
}

/* Componentes básicos */

.container-xl{
	margin: auto;
	width: 100%;
	max-width: 1185px;
	padding-left: 15px;
	padding-right: 15px;
}

.container-sm{
	margin: auto;
	width: 100%;
	max-width: 1080px;
	padding-left: 15px;
	padding-right: 15px;
}

.alert{
	padding: 8px 20px;
	font-family: 'Poppins', sans-serif;
	color: white;
	border-radius: 6px;
	border: none;
}

.alert-danger{ background-color: $vermelho; }

.alert-success{ background-color: $verde; }

.link-underline{
	text-decoration: underline;
	&:hover,
	&:focus,
	&:focus:hover{
		color: inherit;
		text-decoration: underline;
		opacity: 0.7;
	}
}

.card-badge{
	position: relative;
	margin-bottom: 20px;
	width: 100%;
	max-width: 270px;
	font-size: 16px;

	&_image{
		position: relative;
		width: 100%;
		height: 220px;

		&::before{
			content: '';
			width: 0;
			height: 100%;
			margin-left: -4px;
		}

		img,
		&::before{
			display: inline-block;
			vertical-align: middle;
		}

		&_animation{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			transform: translate(-50%, -50%);
		}

		& + p{
			@include media-min992{
				min-height: 69px;
			}
		}

	}

	&_user{
		position: absolute;
		left: 50%;
		top: 0;
		width: 115px;
		height: 115px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;
		transform: translate(-50%, -50%);
	}

	p{
		max-width: 210px;
		margin: 0 auto;
		font-size: 16px;
	}

	.btns-share{
		margin: 20px auto 0;
		max-width: 210px;

		p{
			margin-bottom: 8px;
			text-align: left;
			font-size: 12px;
			color: $azul-escuro;
		}

		.btn {
			width: 100%;
		}
	}

	& + .card-badge{
		margin-top: 70px;

		@include media-min768{
			margin-left: 25px;
			margin-top: 0;
		}
	}

	&:nth-of-type(3n + 4){
		margin-left: 0;
	}
}

.card-box{
	max-width: 360px;
	margin-bottom: 40px;
	padding: 60px 30px 30px;
	background-color: white;
	box-shadow: 0 3px 5px rgba(0,0,0,0.1);

	@include media-min768{
		width: 48%;
		max-width: initial;
		margin-bottom: 80px;
		padding: 60px 40px 50px;

		&:nth-of-type(2n + 3){
			margin-left: 0;
		}
	}

	@include media-min992{
		width: 100%;
		max-width: 360px;
	}

	@include media-min1200{
		&:nth-of-type(2n + 3){
			margin-left: 25px;
		}
		&:nth-of-type(3n + 4){
			margin-left: 0;
		}
	}

	&.card-simples{
		padding: 30px;
		margin-bottom: 20px;

		@include media-min768{
			padding: 50px 40px;
			margin-bottom: 30px;
		}

		& + .card-simples{
			margin-top: 20px;

			@include media-min768{
				margin-top: 0;
			}
		}

		p{
			max-width: 100%;
			font-size: 22px;
			font-family: 'Poppins', sans-serif;
			color: $azul-escuro;
		}
	}
}

.list-flex{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	@include media-min768{
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.loader{
	position: relative;
	width: 100%;
	height: 140px;

	.bola {
		position: absolute;
		left: 50%;
		bottom: 0;
		z-index: 2;
		width: 50px;
		height: 50px;
		background: url('../img/img-bola.svg') no-repeat center;
		background-size: cover;
		border-radius: 50%;
		-webkit-animation: bola 1s linear infinite;
		animation: bola 1s linear infinite;
	}

	.sombra{
		position: absolute;
		left: 50%;
		bottom: 0;
		z-index: 1;
		width: 40px;
		height: 8px;
		background-color: rgba(0,0,0,0.1);
		border-radius: 50%;
		transform: translateX(-50%);
		-webkit-animation: sombra 1s linear infinite;
		animation: sombra 1s linear infinite;
		-webkit-filter: blur(1px); /* Safari 6.0 - 9.0 */
		filter: blur(1px);
	}
}

@keyframes bola {
	0%{
		-webkit-transform: translate(-50%, -80px) scaleX(0.9);
		transform: translate(-50%, -80px) scaleX(0.9);
		animation-timing-function: ease-in;
	}
	45%{
		-webkit-transform: translate(-50%, 0) scaleX(0.85);
		transform: translate(-50%, 0) scaleX(0.85);
		animation-timing-function: ease-in;
	}
	50%{
		-webkit-transform: translate(-50%, 0) scaleY(0.75) scaleX(1.15);
		transform: translate(-50%, 0) scaleY(0.75) scaleX(1.15);
	}
	100%{
		-webkit-transform: translate(-50%, -80px) scaleX(0.9);
		transform: translate(-50%, -80px) scaleX(0.9);
		animation-timing-function: ease-in;
	}
}

@-webkit-keyframes bola {
	0%{
		-webkit-transform: translate(-50%, -80px) scaleX(0.9);
		transform: translate(-50%, -80px) scaleX(0.9);
		animation-timing-function: ease-in;
	}
	45%{
		-webkit-transform: translate(-50%, 0) scaleX(0.85);
		transform: translate(-50%, 0) scaleX(0.85);
		animation-timing-function: ease-in;
	}
	50%{
		-webkit-transform: translate(-50%, 0) scaleY(0.75) scaleX(1.15);
		transform: translate(-50%, 0) scaleY(0.75) scaleX(1.15);
	}
	100%{
		-webkit-transform: translate(-50%, -80px) scaleX(0.9);
		transform: translate(-50%, -80px) scaleX(0.9);
		animation-timing-function: ease-in;
	}
}

@keyframes sombra {
	0%{
		background-color: rgba(0,0,0,0.1);
		-webkit-transform: translateX(-50%) scaleX(0.4);
		transform: translateX(-50%) scaleX(0.4);
		animation-timing-function: ease-in;
	}
	50%{
		background-color: rgba(0,0,0,0.3);
		-webkit-transform: translateX(-50%) scaleX(1.1);
		transform: translateX(-50%) scaleX(1.1);
		animation-timing-function: ease-in;
	}
	100%{
		background-color: rgba(0,0,0,0.1);
		-webkit-transform: translateX(-50%) scaleX(0.4);
		transform: translateX(-50%) scaleX(0.4);
		animation-timing-function: ease-in;
	}
}

@-webkit-keyframes sombra {
	0%{
		background-color: rgba(0,0,0,0.1);
		-webkit-transform: translateX(-50%) scaleX(0.4);
		transform: translateX(-50%) scaleX(0.4);
		animation-timing-function: ease-in;
	}
	50%{
		background-color: rgba(0,0,0,0.3);
		-webkit-transform: translateX(-50%) scaleX(1.1);
		transform: translateX(-50%) scaleX(1.1);
		animation-timing-function: ease-in;
	}
	100%{
		background-color: rgba(0,0,0,0.1);
		-webkit-transform: translateX(-50%) scaleX(0.4);
		transform: translateX(-50%) scaleX(0.4);
		animation-timing-function: ease-in;
	}
}

.modal-loader{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba( 255,255,255,0.75 );
	z-index: 6;

	.loader{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

/* Componentes específicos */

.qntd-pontos{
	display: flex;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 20px;

	&_label{
		position: absolute;
		top: 50%;
		left: 15px;
		color: $amarelo;
		transform: translateY(-50%);
	}

	button.btn{
		padding-left: 12px;
		padding-right: 12px;
	}

	input.qntd-pontos_input{
		margin-left: 4px;
		margin-right: 4px;
		max-width: 120px;
		height: 48px;
		padding: 0;
		background-color: transparent;
		text-align: center;
		font-size: 20px;
		color: white;
		border: 2px solid white;

		&:focus,
		&:hover{
			border-color: white;
		}
	}
}

.lista-noticias{
	padding-top: 55px;
	padding-bottom: 70px;
	margin-right: -15px;

	@media (min-width: 1060px){ margin-right: 0; }

	@include media-min992{ padding-top: 90px; padding-bottom: 100px; }

	.titulo{ margin-bottom: 15px; }
	.btn{ margin-top: 30px; }

	&.sem-overflow{
		margin-right: 0;

		.row-noticias{
			min-width: 100%;
			.card-noticia{
				margin-bottom: 30px;

				@include media-max767{ width: 100%; }
			}
		}
	}
}

.header-titulo{
	position: relative;
	margin-bottom: 10px;

	@include media-min768{ margin-bottom: 60px; }

	.titulo{
		margin-bottom: 20px;
		max-width: 80%;

		@include media-min768{ max-width: 1000px; margin-bottom: 20px; }
	}

	p{
		margin-bottom: 0;
		font-size: 14px;
		color: $azul-escuro;

		@include media-min768{ font-size: 20px; }
	}

	&.com-sucesso{
		margin-bottom: 15px;

		@include media-min768{ margin-bottom: 30px; }

		.subtexto{
			font-size: 22px;
			@include media-min992{ font-size: 24px; }
		}
	}
}

.cadastre-se{
	margin-top: 20px;
	margin-bottom: 0;
	text-align: center;

	@include media-min768{ text-align: left; }
}

.form-login{
	text-align: center;

	@include media-min768{ text-align: left; }
}

.form-sucesso{
	padding-bottom: 130px;
	.btn{ margin-top: 30px; }
}

.trocar-avatar{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	@media(min-width: 992px) {
		flex-direction: column;
		justify-content: center;
	}
	.input-file{
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
	}

	.perfil-avatar{
		min-width: 118px;
		width: 118px;
		height: 118px;
		background-color: #B1B1B1;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 50%;
		margin-right: 20px;
	
		@include media-min992{
			min-width: 183px;
			width: 183px;
			height: 183px;
			margin-bottom: 12px;
			margin-right: 0;
		}
	}

	.btn-file {
		max-width: 172px;
	}
}

.noticia-conteudo{
	margin-top: 40px;

	.img{
		position: relative;
		margin-bottom: 15px;
		width: 100%;
		height: 330px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		@include media-min768{
			float: left;
			margin-right: 50px;
			width: 330px;
		}

		figcaption{
			position: absolute;
			bottom: 0;
			width: 100%;
			min-height: 65px;
			padding-top: 25px;
			color: white;
			font-size: 16px;
			font-family: 'Poppins', sans-serif;
			text-transform: uppercase;
			background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
			background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );

			&::before{
				content: '';
				display: inline-block;
				width: 13px;
				height: 13px;
				background-color: $verde;
			}

			span{
				display: inline-block;
				vertical-align: top;
				width: calc(100% - 20px);
				padding-bottom: 10px;
			}
		}
	}

	p{
		font-size: 15px;
		line-height: 20px;
		color: $azul-escuro;
	}

	img,
	p img,
	iframe,
	p iframe{
		width: 100% !important;
	}

	& + .lista-noticias{ padding-top: 40px; }
}

.sect-estatisticas{

	.lista-numeros{
		margin-bottom: 100px;

		.row:first-of-type{

			.col-xs-12:last-of-type{
				margin-top: 15px;
				@include media-min768{ text-align: right; margin-top: 0; }

				.form-group{
					width: 100%;
				}

				.select{
					display: inline-block;
					vertical-align: middle;
					margin-left: 15px;
					width: auto;
					min-width: 200px;

					.btn{ font-size: 14px; }
				}
			}
		}

		.row:last-of-type{ margin-top: 20px; margin-bottom: 30px; }

		ul{
			margin-bottom: 0;
			color: $azul-escuro;
			font-size: 14px;
			p{ margin-bottom: 0; }
			b{ float: right; }
		}
	}
}

.list-underline{

	li{
		padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #B1B1B1;
	}
}

.erro-conteudo{
	text-align: center;
	padding-bottom: 35px;
	font-family: 'Poppins', sans-serif;
	color: $azul-escuro;

	@include media-min768{
		padding-bottom: 100px;
	}

	.erro-titulo{
		position: relative;
		margin-top: 0;
		margin-bottom: 0;
		font-size: 50px;
		font-weight: 700;
		text-transform: uppercase;

		@include media-min768{
			font-size: 80px;
		}
	}

	.erro-msg{
		font-size: 14px;
		margin-bottom: 20px;

		@include media-min768{
			font-size: 20px;
		}
	}
}

.tab-padrao{
	.badge{
		margin-left: 2px;
		background-color: $azul-escuro;
		width: auto;
		height: auto;
		padding: 2px 4px;
	}
	.nav-tabs{
		border-bottom: 5px solid $azul-escuro;
	}
	.nav-item{
		margin-bottom: 0;
		margin-right: 4px;
		.nav-link{
			font-family: 'Poppins', sans-serif;
			font-size: 14px;
			text-transform: none;
			background-color: #FFFFFF;
			border: none;
			border-radius: 0;
			height: 35px;
			padding: 9px 15px;
			margin: 0;
		}
		&.active{
			.nav-link{
				background-color: $vermelho;
				color: #FFFFFF;
				border: none;

				&.nav-link-tab{
					background-color: $azul-escuro;
					border-radius: 7px 7px 0 0  !important;
				}
			}
			.badge{
				background-color: #FFFFFF;
				color: $azul-escuro;
			}
		}
	}
	.tab-pane{
		padding-top: 30px;
		padding-bottom: 30px;
		min-height: 351px;
	}
}

.minha-torcida{

	&-lista{
		list-style: none;
		padding: 0;
		margin: 0;

		li{
			border-bottom: 1px solid #ADADAD;
			padding-top: 19px;
			padding-bottom: 19px;
			min-height: 91px;

			&:last-of-type{ border-bottom: none; }
		}

		.btn-confirma,
		.btn-remover-amigo {
			margin-top: 10px;
		}

		.amigo-info {
			&-conteudo {
				&-data {
					line-height: 15px;
				}
			}

			&-icone {
				margin: 0 12px;

				@include media-min768{
					margin: 0 22px;
				}
			}
		}

		.perfil{
			position: relative;
			&-avatar{
				width: 45px;
				height: 45px;
				border-radius: 50%;
				margin-right: 0;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}
			&-time{
				position: absolute;
				bottom: -7px;
				right: -5px;
				width: 27px;
				height: 27px;
				border: 1px solid #E4E4E4;
				border-radius: 100%;
				background-color: #FFFFFF;
				background-size: 70%;
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		&--v2{

			li{
				border: none;
				padding-top: 16px;
				padding-bottom: 0;
				min-height: initial;
			}

			.amigo-info{

				@include media-min992{
					font-size: 20px;
				}
			}
		}
	}

	&-lista--flex{
		display: flex;
		flex-direction: column;

		li{
			display: flex;
			align-items: center;
			justify-content: space-between;

			.amigo-info{
				flex: 1;
			}

			.btn + .btn{
				margin-left: 8px;
				margin-bottom: 0;
			}

		}

		.btn-confirma,
		.btn-remove{
			margin-top: 0;
			width: 36px;
			height: 36px;
		}
	}
}

.votacao{
	margin-bottom: 95px;
	width: 118%;
	padding-left: 95px;

	@include media-min768{
		width: 105%;
	}

	&,
	.highcharts-container{
		overflow: visible !important;
	}

	.highcharts-exporting-group,
	.highcharts-credits{
		display: none !important;
	}

	.jogador{
		display: block;
		margin-top: 0;
		width: 280px;
		height: 100%;

		&-img{
			display: inline-block;
			vertical-align: middle;
			width: 95px;
			height: 90px;
			margin-right: 15px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}

		&-time{
			display: block;
			vertical-align: middle;
			font-size: 10px;
			font-family: 'Poppins', sans-serif;
			text-transform: uppercase;

			@include media-min768{
				display: inline-block;
				font-size: 14px;
				&::after{
					content: ' |';
				}
			}
		}

		&-nome{
			display: inline-block;
			vertical-align: middle;
			font-size: 18px;
			font-family: 'Poppins', sans-serif;
			text-transform: uppercase;

			@include media-min768{
				font-size: 14px;
				font-family: 'Poppins', sans-serif;
			}
		}

		&-separador{
			display: inline-block;
			vertical-align: middle;
			width: 55%;
			@include media-min992{
				width: calc(100% - 110px);
			}
		}
	}

	.total{
		color: #000;
		font-family: 'Poppins';
		font-size: 16px;

		@include media-min768{
			font-size: 20px;
		}
	}

	g.highcharts-series,
	.highcharts-point,
	.highcharts-markers,
	.highcharts-data-labels {
		transition: none 0ms;
	}

	.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
	.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
	.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
	.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
		opacity: 1;
	}

}

.craque{
	&-lista{
		margin-top: 30px;
		margin-bottom: 20px;
		@include media-min768{ margin-top: 0; }
		@include media-min992{ margin-bottom: 50px; }
		ul{
			padding: 0;
			margin-right: 0;
			margin-left: -5px;
			width: calc(100% + 5px);
			list-style: none;
		}
		li{
			display: inline-block;
			margin-left: 5px;
			margin-right: 5px;
			margin-bottom: 50px;
			width: 46%;

			@include media-min768{ width: 31.6%;}
			@include media-min992{ width: 23.6%; }
			@include media-min1200{ width: 18.8%; }
		}

	}
	&-card{
		position: relative;
		font-family: 'Poppins', sans-serif;
		text-align: center;
		max-width: 330px;
		margin-left: auto;
		margin-right: auto;
		&-img{
			width: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			border-radius: 6px;
			position: relative;
			&::after {
				content: "";
				display: block;
				padding-bottom: 70%;
			}
		}
		&-clube{
			width: 25%;
			margin-top: -15%;
			margin-bottom: -5%;
			margin-left: 5%;
			position: relative;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 70%;
			background-color: #FFFFFF;
			border: 3px solid #FFFFFF;
			border-radius: 100%;
			&::after {
				content: "";
				display: block;
				padding-bottom: 100%;
			}
		}
		&-time,
		&-jogador{
			color: #000000;
			margin-bottom: 0;
			text-transform: uppercase;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		.btn.btn-sm{
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	&-sucesso{
		margin-bottom: 70px;

		@include media-min992{
			margin-bottom: 140px;
			text-align: center;
		}

		.btn {
			width: max-content;
		}

		&-titulo{
			font-size: 30px;
			margin-bottom: 30px;
			max-width: 320px;
			@include media-min992{
				max-width: 100%;
				font-size: 50px;
				margin-bottom: 50px;
			}
		}
		.craque-card{
			@include media-max991{ max-width: none; }
		}
	}
}

.modal-center{
	.modal-dialog{
		margin: 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) !important;
	}
}

.modal-v2{

	.modal-content{
		border-radius: 12px;
	}

	.modal-body{
		padding: 24px 26px;
		color: #14285A;
		font-family: 'Poppins', sans-serif;
		font-size: 14px;
		text-align: center;
	}

	.modal-title{
		margin-bottom: 20px;
		font-size: 20px;
		font-family: 'Poppins', sans-serif;
		color: inherit;
	}

	.close{
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 24px;
		right: 24px;
		width: 16px;
		height: 16px;
		padding: 0;
		color: #14285A;
		font-size: 12px;
		border: 1px solid #14285A;
		border-radius: 2px;
		opacity: 1;
	}

	&.modal-sm{
		width: 100%;

		.modal-dialog{
			width: 90%;
			max-width: 365px;
		}
	}

	&.modal-atualizacao{

		.modal-dialog{
			max-width: 520px;
		}

		.modal-body{
			text-align: left;
		}

		.modal-title{
			margin-bottom: 10px;
			font-size: 32px;
		}

		.btns{
			display: flex;
			align-items: center;

			.btn-v2 + .btn-v2{ margin-left: 24px; }
		}
	}
}

.politica-privacidade {
	padding-top: 165px;
	padding-bottom: 80px;
	color: $azul-escuro;

	@include media-min992 { padding-top: 175px; }

	&-lista {
		margin-top: 24px;
		margin-bottom: 40px;

		@include media-min768 { margin-bottom: 35px; }

		li {
			font-size: 14px;
			line-height: 20px;
		}
	}

	&-conteudo {
		margin-bottom: 24px;

		&:first-of-type {
			margin-top: 45px;
		}

		&:last-of-type {
			margin-bottom: 38px;
		}

		p {
			margin: 0;
		}
	}

	&-contato {
		margin-bottom: 40px;

		h3 {
			margin-bottom: 24px;
		}
	}

	.subtexto{
		font-size: 16px;
		line-height: 18px;
	}

	&.atualizacao{
		margin-top: 165px;
		margin-bottom: 30px;
		max-height: 560px;
		padding-top: 0;
		padding-bottom: 0;
		overflow: auto;
	}
}

@media print {
	header,
	.faixa-principal,
	.container-btnMenu,
	.btn,
	.img-bola{
		display: none;
	}
}

.scroll {
	&_container {
		@include media-max991 {
			max-width: 100%;
			padding: 0;
			overflow-x: scroll;
		}
	}

	&_snap {
		scroll-snap-type: mandatory;
		scroll-snap-type: x mandatory;

		& > * {
			scroll-snap-stop: always;
		}
	}

	&_center {
		& > * {
			scroll-snap-align: center;
		}
	}
}

.desafie_chamada {
	position: relative;
	color: white;
	overflow: hidden;
	@include media-min768 { overflow: visible; }
	
	p {
		max-width: 330px;
	}
	
	.container {
		display: flex;
		align-items: center;
	}
	
	&-conteudo {
		position: relative;
		padding: 50px 0;
		z-index: 2;
		@include media-min992 { padding: 65px; }
	}

	&-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		flex: 1;
		mix-blend-mode: multiply;
		opacity: 0.22;
		
		@include media-min768 {
			position: relative;
			text-align: right;
			opacity: 1;
			mix-blend-mode: initial;
		}

		@include media-min992 {
			margin-top: -70px;
		}

		img {
			width: 100%;
			@include media-min768 {
				width: auto;
				max-width: 50vw;
			}
		}
	}

	&-logo{
		margin-bottom: 10px;
		max-width: 260px;
	}
}

.partidas{
	position: relative;
	height: 200px;
	overflow: hidden;

	.faixa{
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 80%;
		background-color: #EEEEEE;
	}

	.item{
		position: relative;
		text-align: center;
		font-family: 'Open Sans', sans-serif;
		border: 1px solid #FFFFFF;
		border-radius: 6px;

		&_body {
			position: relative;
			z-index: 2;
			background-color: white;
			padding: 11px 16px 5px;
		}

		&_footer {
			position: relative;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			padding: 6px 11px;
			background-color: #FF3C00;
			border-radius: 0 0 6px 6px;

			.item_footer_odds-holder {
				display: flex;
				align-items: center;
				gap: 4px;

				&_odd {
					display: flex;
					align-items: center;
					gap: 10px;
					padding: 6px;
					height: 30px;
					min-height: 30px;
					max-height: 30px;
					min-width: 52px;
					border-radius: 3px;
					border: 1px solid #DDD;
					background: #FFF;
					font-family: 'Poppins', sans-serif;

					&_result {
						color: #A7A7A7;						
						font-size: 10px;					
						line-height: normal;
					}

					&_value {
						color: #498234;					
						font-size: 10px;					
						font-weight: 700;
						line-height: normal;
					}
				}
			}
		}

		& + .item{
			margin-left: 14px;			
		}

		.data{
			color: #888;		
			font-size: 10px;		
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
			margin-bottom: 4px;
		}

		.partida {
			justify-content: center;

			.partida_img_holder {
				display: flex;
				align-items: center;
				gap: 6px;

				height: initial;
				width: initial;
				padding: 0;

				.partida_img {
					width: 32px;
				}

				.partida_img_holder_team {
					color: #010C29;
					font-family: 'Poppins', sans-serif;
					font-size: 14px;						
					line-height: normal;
				}
			}

			.partida_resultado {
				display: flex;
				align-items: center;
				gap: 4px;

				background-color: transparent;
				margin: 0 6px;
				font-family: 'Poppins', sans-serif;

				font-weight: 400;

				color: #010C29;

				padding: 0;

				&_placar {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 25px;
					height: 27px;

					border-radius: 3px;
					border: 1px solid #DDD;
					background: #FFF;
				
				
					font-size: 14px;				
					line-height: normal;
				}

				&_divisor {
					font-size: 11px;
				}
			}
		}

		.acesso{
			position: absolute;
			left: 50%;
			bottom: -5px;
			z-index: 1;
			width: calc(100% - 12px);
			height: 46px;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			padding: 0 0 10px;
			background-color: #006EC8;
			color: white;
			font-size: 14px;
			font-weight: 700;
			border-radius: 8px;
			transform: translateX(-50%);
			transition: all ease 0.4s;
		}

		&.realizado{
			.data{
				color: #ACADB1;
			}
			.acesso{
				background-color: #ACADB1;
			}
		}

		&.acontecendo-agora{
			.data{
				color: #E8BE61;
			}
			.acesso{
				background: rgb(205,157,93);
				background: linear-gradient(90deg, rgba(205,157,93,1) 0%, rgba(245,205,98,1) 100%);
				color: #14285A;
			}
		}

		&.proximo{
			.data{
				color: #14285A;
			}
			.acesso{
				background-color: #14285A;
			}
		}

		&:hover{

			.acesso{
				bottom: -38px;
			}
		}
	}

	&_lista{
		position: relative;
		z-index: 2;
		//display: flex;
		width: 100%;
		max-width: 1710px;
		margin: 0 auto;
		padding: 19px 30px;
		list-style: none;
	}

	.slick-list{
		overflow: visible;
	}
}

.jogos_wrapper{
	display: flex;
	column-gap: 15px;
	margin-top: 30px;
	margin-bottom: 30px;	
	flex-wrap: wrap;
	row-gap: 15px;

	.jogo{
		width: 100%;

		@media(min-width: 500px) {
			width: calc(50% - 15px);
		}

		@include media-min992{
			min-width: initial;
			width: calc(25% - 15px);
		}
	}

	&.duplo{

		.jogo{

			@include media-min992{
				min-width: initial;
				width: calc(50% - 15px);
			}
		}
	}

	&.trios {
		.jogo {
			@include media-min992{
				min-width: initial;
				width: calc(33.33% - 15px);
			}
		}
	}
}

.lista-lugares{
	display: flex;	
	column-gap: 8px;
	margin-bottom: 0;
	padding: 0 8px;
	list-style: none;

	@media(min-width: 992px) {
		align-items: center;
		justify-content: center;
		min-width: 560px;
	}
}

.adicionados{
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	text-align: left;
	color: #1F1960;

	.item{
		position: relative;
		display: flex;
		flex-direction: column;

		@include media-min992{
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
		}

		& + .item{ margin-top: 24px; }

		.avatar-wrapper{
			margin-bottom: 12px;
			margin-right: 0;

			@include media-min992{
				margin-bottom: 0;
				margin-right: 20px;
			}

			.avatar{
				display: none;

				@include media-min992{
					display: block;
				}
			}

			.nome{
				margin-bottom: 0;
				margin-left: 0;

				@include media-min992{
					margin-left: 20px;
				}
			}
		}

		&-palpite{
			display: flex;
			flex-direction: column;
			margin-top: 12px;
			font-size: 16px;

			@include media-min992{
				position: absolute;
				left: 50%;
				margin-top: 0;
				align-items: center;
				flex-direction: row;
				min-width: 285px;
				transform: translateX(-50%);
			}

			.palpite{
				display: flex;
				align-items: center;
				margin-bottom: 8px;

				@include media-min992{
					margin-right: 16px;
					margin-bottom: 0;
				}
			}
		}
	}

	.nome{
		flex: 1;
		margin: 0 0 0 20px;
	}
}

.avatar{
	width: 46px;
	height: 46px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 50%;

	&-wrapper{
		display: flex;
		align-items: center;
		margin-right: 20px;
	}
}

.sucesso-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 370px;
	margin: auto;
	padding-top: 80px;
	padding-bottom: 80px;
	text-align: center;

	.titulo{
		max-width: 90%;
	}
}

.modal-cookies{
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 20;
	width: 100%;
	padding: 30px 0;
	background-color: #14285A;
	font-family: 'Poppins', sans-serif;
	font-size: 10px;
	color: white;

	.container{
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 900px;
	}

	.texto{
		flex: 1;
		margin-bottom: 0;
		margin-right: 40px;
	}

	.link{
		color: #FFC237;
		text-decoration: underline;
	}

	&.close{
		display: none;
	}
}


.btns_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.btn {
		width: 100%;
	}

	@media(min-width: 768px) {
		flex-direction: row;

		.btn {
			width: initial;
		}
	}
}

.secao-opcoes{
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: $cinza-2;

	.card-opcoes-box{
		margin:0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items:center;
		padding-left: 0;
		padding-right: 0;
		gap: 17px;
	}
}

.card-produto-box{
	display:flex;
	flex-direction: column;
	justify-content:space-between;

	@include media-min768{
		justify-content: flex-start;
		flex-wrap: wrap;
	  flex-direction: row;
	}

	.card-produto {
		@include media-min768{
			margin-right: 28px;
			margin-bottom: 32px;

			&:nth-child(4n) {
				margin-right: 0;
			}
		}		
	}
 }

 .rodada_jogo {
	 display: flex;
	 align-items: flex-end;
	 justify-content: space-between;
	 padding-bottom: 20px;
	 margin-bottom: 20px;
	 border-bottom: 1px solid #B1B1B1;

	 &:last-child {
		 border: 0;
		 padding-bottom: 0;		
		 margin-bottom: 0;
	 }
 }

.home-liga_sect {
	padding: 30px 0 0;

	@media(min-width: 992px) {
		padding: 50px 0 0;
	}
}

.noticia-interna_caption {
	background-color: $cinza-2;
	padding: 8px;
	font-size: 14px;
	color: $azul-escuro;

	@media(min-width: 992px) {
		padding: 22px 25px;
		font-size: 16px;
	}
}

.outras-noticias_wrapper {	
	display: flex;
	flex-wrap: wrap;
	row-gap: 15px;	

	.card-noticia{
		width: 100%;
		

		@media(min-width: 500px) {
			width: calc(50% - 20px);
		}

		@include media-min992{
			min-width: initial;
			width: calc(25% - 20px);
		}
	}
}

 .trocar-pontos-info{
	 margin-top: 15px;
	 display: flex;
	 flex-direction: column;
	 justify-content: space-between; 

	 @include media-min768{
		flex-direction: row;
	  }
}

.desafio-sucesso_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	max-width: 400px;
	margin: 0 auto;
	padding: 50px 0;
}

.info-extra {
	opacity: 0.48;
	margin-top: 20px;
}

.banner-nivea_logo {
	max-width: 87px;
}

.to-no-jogo-btns_wrapper {
	max-width: 250px;
}

.banner-liga_container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;	
}

.busque-amigos{
   display:flex;
   flex-direction: column;
   @include media-min768{ flex-direction:row;}

  &-item{
	width: 100%;
	@include media-min768{ width: 50%;}
  }
}

.trocar-produtos-info{
	margin-bottom: 10px;
	@include media-min768{ margin-bottom: 70px;}
}

.detalhe-liga_content {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 50px;

	@media(min-width: 992px) {
		flex-direction: row;
		margin-bottom: 100px;
	}

	.content {
		margin-top: 20px;

		@media(min-width: 992px) {
			margin-top: 0;
			margin-left: 32px;		
		}

		&_intro {
			display: flex;
			align-items: flex-start;
			flex-direction: column;			
			margin-bottom: 22px;

			@media(min-width: 992px) {
				justify-content: space-between;
				align-items: center;
				flex-direction: row;
			}

			.btn {
				margin-top: 16px;

				@media(min-width: 992px) {
					margin-top: 0;
				}
			}
		}

		&_compartilhar {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: flex-start;
			margin-top: 22px;

			@media(min-width: 992px) {
				flex-direction: row;
				align-items: center;
			}

			.btn {
				margin-top: 20px;
				margin-right: 20px;

				@media(min-width: 992px) {
					margin-left: 20px;
					margin-right: 0;
					margin-top: 0;
				
				}
			}
		}
	}
}

.trocar-produtos{
	display:flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;

	@include media-min992{ 
		flex-direction:row;
		align-items: center;
	}

	&-img {
		width: 100%;
		max-width: 500px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 12px;			

		&::after {
			content: "";
			display: block;
			padding-bottom: 66.27%;
		}

		@media(min-width: 992px) {
			width: 35%;
			margin-right: 50px;
			&::after {			
				padding-bottom: 104%;
			}
		}
	}
	
	&-padrao{
		width: 100%;
		@include media-min992{ 
			width: 50%;
		
		}
	}

	&-padrao{
		margin-top: 25px;
		@include media-min992{ 
			margin-top: 0;			
		}

		&-pontos{
			display:flex;
	        flex-direction: column;
			align-items: flex-start;
			margin: 2rem 0;

	        @include media-min992{ 
				flex-direction:row;
		    	align-items: center;
			}

			.possui-pontos{
				margin-bottom: 0;
				margin-left: 0;
				@include media-min992{ margin-left: 2rem;}
			}
		}
	}
     
    &-img{
		position: relative;
		overflow: hidden;
		display: flex; 
		justify-content: center;
		align-items: center;

       .img{
		   height: 335px;
		   width: 100%;
		   border-radius: 25px;

		   @include media-min768{
			height: auto;
			width: auto;
			border-radius: 0;
		   }
	   }

	}

	&-aceite{
		margin-top: 25px;
		@include media-min768{ margin-top: 0;}
	}
}

.lista-notificacoes{
	margin-top: 30px;
	margin-bottom: 60px;

	.notificacao{
		padding-bottom: 20px;
		font-size: 14px;
		color: $azul-escuro;
		border-bottom: 1px solid $cinza-5;

		&-item{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 0;
		}

		.avatar{
			margin-right: 20px;

			&_wrapper{
				margin-right: 0;
			}
		}

		&_texto{
			flex: 1;
		}

		& + .notificacao{
			padding-top: 20px;
		}

		a:hover{
			color: $azul;
			opacity: 1;
		}

		&:last-of-type{
			padding-bottom: 0;
			border-bottom: none;
		}
	}
}

.beneficios-nft_sect {
	overflow: hidden;
	padding-bottom: 80px;

	@media(min-width: 992px) {
		padding-bottom: 54px;
	}

	.beneficios-nft_cards-wrapper {			
		margin-top: 32px;

		@media(min-width: 992px) {
			display: flex;
			align-items: stretch;
			justify-content: center;			
		}		

		.beneficios-nft_card {			
			width: 100%;			
			background-color: #00081E;
			border-radius: 20px;
			margin-right: 10px;
			height: inherit;
			overflow: hidden;

			@media(min-width: 992px) {
				width: 30%;
				max-width: 311px;
				margin: 0 12.5px;				
			}

			.image_holder {
				width: 100%;

				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;

				&::after {
					content: '';
					display: block;
					padding-bottom: 65%;
				}
			}

			.card_body {
				padding: 30px 20px 35px;
			}

			.card_text {				
				margin-bottom: 0;
				color: white;
				font-size: 16px;
				font-weight: 600;		
				text-align: center;
			}
		}

		.slick-list {
			overflow: visible;
		}

		.slick-track {
			display: flex;
			align-items: stretch;
		}

		.slick-dots {
			bottom: -40px;

			li {
				button {
					&::before {
						font-size: 10px;
						color: #D9D9D9;
					}
				}
			}

			.slick-active {
				button {
					&::before {						
						color: #00081E;
					}
				}
			}
		}
	}
}

.resgate-nft_sect {
	padding: 40px 0;
	background-color: #00081E;

	@media(min-width: 992px) {
		padding: 78px 0 67px;
	}

	.titulo {
		text-align: center;
		color: white;
		margin-bottom: 20px;
	}

	.intro_text {
		color: white;
		text-align: center;
		margin-bottom: 32px;
		font-size: 16px;
		line-height: 24px;

		@media(min-width: 992px) {
			margin-bottom: 50px;
		}
	}

	.resgate-nft_box-wrapper {
		margin-bottom: 37px;

		@media(min-width: 992px) {
			display: flex;
			flex-wrap: wrap;
			align-items: stretch;
			justify-content: space-between;
			margin-bottom: 56px;
		}

		.resgate-nft_box {
			background: #C7FF00;
			border-radius: 20px;
			padding: 28px;
			text-align: center;
			margin-bottom: 10px;			

			@media(min-width: 992px) {		
				flex: 1;
				margin: 0 6px;
				padding: 32px 18px;				
			}

			@media(min-width: 1400px) {					
				padding: 32px 24px;
			}

			.icone {
				margin-bottom: 20px;
			}

			.title {
				font-size: 18px;
				font-weight: 500;
				line-height: 24px;			
				color: #010C29;			
				margin-top: 0;				
			}

			.text {
				font-size: 16px;
				line-height: 24px;
				color: rgba(0, 0, 0, 0.52);
				margin-bottom: 24px;

				@media(min-width: 992px) {
					margin-bottom: 32px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			.link {
				display: inline-block;
				text-decoration-line: underline;
				color: #010C29;
				font-size: 14px;
				line-height: 20px;
			}

		}
	}

	.with-4-cards {		

		.resgate-nft_box {
			@media(min-width: 992px) {
				width: 48%;
				flex: initial;
				margin-bottom: 10px;
			}

			@media(min-width: 1200px) {
				width: initial;
				flex: 1;
				margin-bottom: 0;
			}

		}
	}

	.container{

		.btn{
			padding: 20px 56px;
			font-size: 20px;
			text-transform: initial;
			font-weight: bold;
		}
	}
}

.binance-nft {
	position: relative;
	padding-bottom: 40px;
	padding-top: 32px;

	.row {
		display: flex;
		flex-direction: column-reverse;

		@include media-min992 {
			flex-direction: row;
		}
	}
}

.logado-resgate {

	.container {
		@include media-min992 {
			height: auto;
		}
	}

	.area-texto-nft {
		@include media-min992 {
			margin-top: 56px;
			margin-bottom: 49px;
		}
	}
}

.logado-resgatado {

	.area-texto-nft {
		@include media-min992 {
			margin-top: 85px;
		}
	}

	.btn-nft {
		margin-top: 32px;
	}
}

.celular {
	margin: auto;

	@include media-min992 { 
		width: 406px;
		height: 431px;
	}
}

.celular-nft {
	width: 343px;
	height: 364px;

	@include media-min992 { 
		position: absolute;
		top: -90px;
		width: 406px;
		height: 431px;
	}

	&-normal {
		top: 0;
	}
}

.box-codigo-nft {
	display: none;
	@include media-min992 {
		display: block;
		margin-top: 27px;
		background: #C8FE02;
		border-radius: 20px;
		padding: 32px 40px;
		width: 418px;
	}

	.text-box-nft {
		font-size: 20px;
		line-height: 30px;
		color: #1F1960;
		margin-bottom: 16px;
	}

	.codigo-nft {
		font-weight: 700;
		font-size: 32px;
		line-height: 48px;
		letter-spacing: 0.19em;
		color: #1F1960;
		margin-bottom: 0;

		.icone-copiar {
			color: #1F1960;
			margin-left: 29px;
			cursor: pointer;

			&:hover {
				color: lighten(#1F1960, 8%);
			}
		}
	}

	&.resgatado{
		padding: 32px 24px;

		.text-box-nft{
			margin-bottom: 0;
			font-size: 24px;
		}
	}
}

.btn-nft {
	display: none;

	@include media-min992 {
		display: block;
		width: 416px;
		padding: 20px 32px;
		background: #1F1960;
		border-radius: 8px;
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		text-align: center;
		color: #FFFFFF;
		margin-top: 24px;
		outline: none;
		border: none;

		&:hover {
			background-color: lighten(#1F1960, 8%);
		}
	}

	&.last {
		margin-bottom: 72px;
	}
}

.btn-nft-mobile {
	white-space: nowrap;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 20px 103px;
	background: #1F1960;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	color: #FFFFFF;
	outline: none;
	border: none;	

	&:hover {
		background-color: lighten(#1F1960, 8%);
	}

	&:last-child {
		margin-bottom: 36px;
	}

	@include media-min992 {
		display: none;
	}
}

.box-codigo-nft-mobile {
	background-color: #C8FE02;
	width: 100%;
	padding: 37px 0;
	text-align: center;
	color: #1F1960;

	@include media-min992 {
		display: none;
	}

	.text-box-nft {
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 18px;
	}

	.codigo-nft {
		font-weight: 700;
		font-size: 24px;
		line-height: 36px;
		letter-spacing: 0.2em;
		margin-bottom: 0;

		.icone-copiar {
			margin-left: 31px;
		}
	}
}

.area-texto-nft {
	margin-top: 42px;
	margin-bottom: 18px;

	@include media-min992 {
		margin-top: 135px;
		margin-bottom: 0;
	}

	.titulo-nft {
		font-weight: 700;
		font-size: 26px;
		line-height: 39px;
		color: #1F1960;
		margin-bottom: 26px;
		margin-top: 0;
		text-align: center;

		@include media-min992 {
			font-size: 32px;
			line-height: 48px;
			margin-bottom: 16px;
			text-align: left;
		}
	}

	.texto-nft {
		font-size: 18px;
		line-height: 27px;
		color: #7A7894;
		text-align: center;
		margin-bottom: 32px;

		@include media-min992 {
			font-size: 16px;
			line-height: 24px;
			text-align: left;
			max-width: 416px;
		}
	}
	.btn{
		font-size: 20px;
        font-weight: bold;
        width: 100%;
        padding: 20px 103px;

		@include media-min992{
			width: max-content;
		}
	}
}

.banner-nft{
	padding-top: 45px;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
    align-items: center;
	justify-content: center;
	font-family: 'Poppins', sans-serif;
	position: relative;

	@media (min-width:992px){
		padding-top: 0;
	    min-height: 514px;
   }

	.bg-blur{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		object-fit: cover;
	    z-index: -1;
	}

	.banner-info{
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (min-width:992px){
  		   align-items: flex-start;
		}

		.titulo{
			width: 343PX;
			font-weight: 700;
			font-size: 43px;
			line-height: 48px;
			color: #C7FF00;
			margin-bottom: 40px;
			text-align: center;
	
			@media (min-width:992px){
				width: auto;
				font-size: 56px;
				line-height: 64px;
				text-align: left;
				margin-bottom: 24px;
			}
		}
		.paragrafo{
			width: 343px;
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			color: white;
			margin-bottom: 0;
			text-align: center;
	
			@media (min-width:992px){
				width: auto;
				 line-height: 24px;
				text-align: left;
       			margin-bottom: 24px;

			}
		}

	}

	.img-wrapper{
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;

		@media (min-width:992px){
			align-items: flex-end;  
		}

		.img{
			margin-top: 40px;
			width: 235px;

			@media (min-width:992px){
				width: max-content;
     			margin-top: 90px;
			}
		}
	}

	.btn{
		width: 100%;
		text-transform: none !important;
		border-radius: 0;
		margin-top: 45px;
		font-weight: bold;

		@media (min-width:992px){
			width: max-content;
			padding: 20px 41px;
			font-size: 18px;
			border-radius: 8px;
			margin-top: 0;
		}
	}
}


.binance-sect{
	margin-top: 0;
	margin-bottom: 60px;

	@media (min-width:992px){
		margin-bottom: 100px;
		margin-top: 100px;
	}

	.img-wrapper{
		width: 100%;
		height: 230px;
		background: #0B0E11;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		color: black;
		overflow: hidden;
	
		@media (min-width:992px){
			width: 458px;
		    height: 292px;
    		border-radius: 16px;
		}

		.img{
			position: relative;
			z-index: 2;
		   width: 162px;
           margin-bottom: 15px;

		   @media (min-width:992px){
    			width: 215px;
		   }
		}

		.link-fake{
			position: absolute;
			bottom: 7px;
			right: auto;
			z-index: 2;
			font-size: 14px;
            line-height: 21px;
			margin-bottom: 0;
			
			@media (min-width:992px){
		    	right: 20px;	
    			bottom:24px;
			}
		}

		&::before{
			position: absolute;
			top: -50px;
			right: -70px;
			z-index: 1;
			display: block;
			width: 50%;
			height: 140%;
			background-color: #EAC220;
			transform: rotate(20deg);
			opacity: 0.4;
		}
	}

	.binance-info{
		padding-top: 30px;
		text-align: center;

		@media (min-width:992px){
			padding-top: 0;
			text-align: left;
		}

		.titulo{
			font-weight: 700;
			font-size: 26px;
			line-height: 39px;
			color: #1F1960;
			margin-bottom: 24px;

			@media (min-width:992px){
				font-size: 32px;
		    	line-height: 48px;
				margin-bottom: 4px;
			}
			@media (min-width:1200px){
				font-size: 32px;
		    	line-height: 48px;
				margin-bottom: 24px;
			}
		}
		.paragrafo{
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			margin-bottom: 28px;

			@media (min-width:992px){
				font-size: 16px;
				line-height: 24px;
			}
		}
		.btn{
			font-size: 20px;
			font-weight: bold;
			width: 100%;
			padding: 20px 103px;
	
			@media (min-width:992px){
				width: max-content;
			}
		}
	}
}

.tag{
	font-family: 'Poppins', sans-serif;
	padding: 8px 10px;
	font-size: 12px;
	line-height: 12px;
	font-weight: bold;
	border-radius: 4px;

	&-verde{
		background-color: #C7FF00;
		color: #1E1E1E;
	}
}

.tag-produto {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 21px;	

	.icone {
		margin-right: 5px;
	}

	.text {
		display: inline-block;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;		
	}

	&.tag-nft {
		background-color: #010C29;
		color: #C7FF00;
	}
}

.modal-login{
	display: flex;
	justify-content: center;
	align-items: center;

	.modal-content{
		border: 1px solid #E2E2E2;
        border-radius: 13px;
	}

	.modal-dialog{
		width: 95%;
		max-width: 550px;
	}
  
	.modal-header{
		position: relative;
		margin-bottom: 0;

		.close{
			position: absolute;
			top: 30px;
			right: 30px;
			opacity: 1;
			z-index: 1;

			&-img{
				width: 30px;
				height: 30px;
			}
		}
	}

	
	.modal-body{
		padding: 40px;
		// padding-top: 0;
		font-family: 'Poppins';
		color: #1F1960;
		

		.modal-title{
			font-weight: 700;
			font-size: 40px;
			line-height: 60px;
			letter-spacing: -0.01em;
			margin-bottom: 24px;
		}
		.paragrafo{
			font-size: 16px;
            line-height: 24px;
			margin-bottom: 24px;
		}
		.btn{
			width: 100%;
			text-align: center;
			font-weight: 700;
			font-size: 20px;
			line-height: 28px;
			padding: 16px;
		}
	}
}

