/* ==========================================================================
    Footer
    ========================================================================== */

.footer {
    font-size: 14px;

    .faixa-principal{
        padding: 60px 0 15px;
        position: relative;
        background-image: url('../img/footer-textura.jpg');
        background-position: center;        
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        border-bottom: 10px solid #C7FF00;
      
        @include media-min992{
            padding: 60px 35px 40px 50px;
            border-bottom: 5px solid #C7FF00;    
        }      
      
        & > * {
            position: relative;
            z-index: 2;
        }
      }
      
      .footer-menu{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
        max-width: 1280px;        
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      
        @include media-min992{           
            margin-bottom: 75px;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: row;
            text-align: initial;
        }
      
        > li{
            & + li{ margin-top: 30px; }
            &:last-of-type{ margin-right: 0; }
      
            @include media-min768{
                display: inline-block;
                vertical-align: top;
                margin-top: 0;
      
                & + li{
                  margin-top: 0;
                  margin-left: 40px;
                }
            }
        }
      
        a{
            display: block;
            color: white;
            text-decoration: none;
      
            &:hover,
            &:focus,
            &:focus:hover{
                color: white;
                opacity: 0.7;
            }
        }
      
        .submenu li{
            display: block;
            margin-bottom: 5px;
            &:nth-of-type(2){ margin-top: 0; }
            &:last-of-type{ margin-bottom: 0; }
        }
      
        .ajuste-mobile{
            display: inline-block;
            vertical-align: top;
            margin-right: 50px;
      
            & + li{
                display: inline-block;
                vertical-align: top;
            }
        }
      }
      
      .link{
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        color: #C7FF00;
      }
      
      .sublink{
        font-size: 14px; 
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
      }
      
      .redes-sociais{
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        padding-left: 18px;
        padding-right: 18px;
      
        @include media-min992{ padding-left: 0; padding-right: 0; }
        
        a {
            text-decoration: none;
        }

        a:hover,
        a:focus,
        a:focus:hover{ opacity: 0.7; color: inherit; text-decoration: none; }
      
        p{ margin-bottom: 0; }
      
        ul{ margin-top: 10px; margin-bottom: 0; }
      
        li{
            display: inline-block;
            vertical-align: middle;
        }
      
        .pull-left{
            p{ font-size: 10px; }
      
            li{ 
                margin-right: 18px;

                @media(min-width: 992px) {
                    margin-right: 20px;
                }

                 &:last-of-type{ margin-right: 0; }
            }
      
            .icones-brasileirao{
              font-size: 20px;
              color: white;

              @media(min-width: 992px) {
                  font-size: 24px;
              }
            }
        }
      
        .img-sm{
            display: inline-block;
            vertical-align: middle;
            max-width: 12px;
            margin-right: 5px;
        }
      
        .pull-right{
      
            p,
            span{
                display: inline-block;
                vertical-align: middle;
                font-size: 12px;
            }
        }
      }
      
      .faixa-logos{
        margin-left: auto;
        margin-right: auto; 
        max-width: 960px;

        @include media-min992{
          display: flex;
          align-items: center;
          justify-content: space-between;
            padding: 25px 15px 25px 0;
        }
        @include media-min1200 {
            max-width: 1180px;
        }
        @include media-min1400 {
            max-width: 1365px;
        }
      
      &.container{
        &::before,
        &::after{
          content: none;
        }
      }

        .logos{
            padding-top: 20px;
      
            @include media-min768{
                text-align: center;
            }
      
            @include media-min992{
                text-align: left;
                padding-top: 0;
            }
      
            .footer_logos_lista{
                margin-bottom: 24px;
                display: flex;
                flex-wrap: wrap;
      
                @include media-min992 { margin-bottom: 0; }
      
                .mobile-full {
                    width: 100%;
                    @include media-min992 {
                        width: auto;
                    }
      
                    &::after {
                        @include media-max991 {
                            display: none;
                        }
                    }
                }
      
                li{
                    position: relative;
                    text-align: left;
                    width: 100%;
      
                    @include media-min992 {
                        width: auto;
                    }                   
      
                    & + li{
                        margin: 30px auto 0;              
      
                        @include media-min992{
                            margin: 0 0 0 24px;
                            padding-left: 24px;
                        }
      
                        &::after{
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            display: block;
                            width: 1px;
                            height: 100%;
                            background-color: #C2C2C2;
                            transform: translateY(-50%);
                        }
      
                        @include media-min1200 {
                            margin-left: 32px;
                            padding-left: 32px;
                        }

                        a {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                        
                        a,
                        a:hover {
                            text-decoration: none;
                        }      

                        a + a {
                            margin-left: 32px;
                        }

                      img{
                        max-height: 35px;
                      }

                      .nivea{
                        max-height: 50px;
                      }
      
                        &.border-none {
                            padding-left: 0;
                            &::after { display: none; }
                        }
                    }
                }
            }
        }
      
        .cbf{
            margin-top: 70px;
            padding-bottom: 25px;
            text-align: center;
      
            @include media-min992{
              margin-top: 0;
              padding-bottom: 0;
            }
        }
      }
      
      .direitos{
        padding: 15px 0;
        background-color: #091848;
        font-size: 12px;
        color: white;
        text-align: center;
        p{ margin-bottom: 0; }
      }
}