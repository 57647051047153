/* ==========================================================================
   Carrosseis
   ========================================================================== */

.carousel-brasileirao{

  .container{ align-items: flex-start; }

  .carousel-inner{ height: 100%; }

  .item{
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .carousel-indicators{
    bottom: 10px;
    margin-bottom: 0;

    @include media-min992{ bottom: 24px; }

    .li{
      margin: 0 7px 0 0;
      background-color: $amarelo;
      border: none;
      transition: all ease 0.3s;

      &:hover{ background-color: $amarelo; }

      &.active{
        margin: 0 7px 0 0;
        width: 10px;
        height: 10px;
        background-color: white;
      }
    }
  }
}

.carousel-noticias{

  .carousel-caption{
    bottom: inherit;
    top: 50%;
    z-index: 2;
    max-width: 790px;
    transform: translateY(-50%);

    .titulo{
      @include media-max767{ font-size: 40px; line-height: 40px; }
    }

    .carousel-text{
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
      text-transform: uppercase;

      &::before{
        content: '';
        display: inline-block;
        vertical-align: baseline;
        margin-right: 10px;
        width: 15px;
        height: 15px;
        background-color: $verde;
      }
    }
  }

  .item{
    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: $azul-escuro;
      opacity: 0.72;
    }
  }
}

.carousel-anuncio {
  .carousel-indicators {
    bottom: -50px;

    li {
      background-color: #bdbdbd;

      &.active {
        background-color: rgb(66, 66, 243);
      }
    }
  }

  .carousel-inner {
    .item {
      width: max-content;
      margin: 0 auto;
    }
  }
}


//Carrousel Rodadas
.carousel_rodadas {  

  .slick-arrow {
    top: 5px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .slick-arrow-left {   
    right: 40px;
    
  }

  .slick-arrow-right {  
    right: 0;   
  }

  .slick-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}