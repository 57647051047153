//Color

.c-branco{ color: white !important; }
.c-preto{ color: black !important; }

.c-azul{ color: $azul !important; }
.c-azul-claro{ color: $azul-2da !important; }
.c-azul-escuro{ color: $azul-escuro !important; }
.c-azul-alt{ color: $azul-alt !important; }
.c-vermelho{ color: $vermelho !important; }
.c-vermelho-escuro{ color: $vermelho-escuro !important; }
.c-verde{ color: $verde !important; }
.c-verde-100{ color: $secundaria !important; }
.c-amarelo{ color: $amarelo !important; }

.c-ouro{ color: $ouro !important; }
.c-ouro-texto{ color: $ouro-texto !important; }
.c-prata{ color: $prata !important; }
.c-prata-texto{ color: $prata-texto !important; }
.c-bronze{ color: $bronze !important; }
.c-bronze-texto{ color: $bronze-texto !important; }

.c-cinza-1{ color: $cinza-1 !important; }
.c-cinza-2{ color: $cinza-2 !important; }
.c-cinza-3{ color: $cinza-3 !important; }
.c-cinza-4{ color: $cinza-4 !important; }
.c-cinza-5{ color: $cinza-5 !important; }
.c-cinza-6{ color: $cinza-6 !important; }
.c-cinza-7{ color: $cinza-7 !important; }
.c-cinza-8{ color: $cinza-8 !important; }
.c-cinza-9{ color: $cinza-9 !important; }
.c-cinza-10{ color: $cinza-10 !important; }

//Background-color

.bgc-branco{ background-color: white !important; }
.bgc-preto{ background-color: black !important; }

.bgc-azul{ background-color: $azul !important; }
.bgc-azul-escuro{ background-color: $azul-escuro !important; }
.bgc-azul-alt{ background-color: $azul-alt !important; }
.bgc-vermelho{ background-color: $vermelho !important; }
.bgc-vermelho-escuro{ background-color: $vermelho-escuro !important; }
.bgc-verde{ background-color: $verde !important; }
.bgc-amarelo{ background-color: $amarelo !important; }
.bgc-azul-004 { background-color: #004F74 !important;}

.bgc-ouro{ background-color: $ouro !important; }
.bgc-ouro-texto{ background-color: $ouro-texto !important; }
.bgc-prata{ background-color: $prata !important; }
.bgc-prata-texto{ background-color: $prata-texto !important; }
.bgc-bronze{ background-color: $bronze !important; }
.bgc-bronze-texto{ background-color: $bronze-texto !important; }

.bgc-cinza-1{ background-color: $cinza-1 !important; }
.bgc-cinza-2{ background-color: $cinza-2 !important; }
.bgc-cinza-3{ background-color: $cinza-3 !important; }
.bgc-cinza-4{ background-color: $cinza-4 !important; }
.bgc-cinza-5{ background-color: $cinza-5 !important; }
.bgc-cinza-6{ background-color: $cinza-6 !important; }
.bgc-cinza-7{ background-color: $cinza-7 !important; }
.bgc-cinza-8{ background-color: $cinza-8 !important; }
.bgc-cinza-9{ background-color: $cinza-9 !important; }
.bgc-cinza-10{ background-color: $cinza-10 !important; }

// Border-color
.border-azul {border-color: $azul !important;}
.border-verde {border-color: $verde !important;}

// Pesos e estilos
.fw-200{ font-weight: 200 !important; } // extra-light
.fw-400{ font-weight: 400 !important; } // regular
.fw-500{ font-weight: 500 !important; } // medium
.fw-600{ font-weight: 600 !important; } // semi-bold
.fw-700{ font-weight: 700 !important; } // bold

// Tamanhos
.fs-8{ font-size: $fs-8 !important; }
.fs-10{ font-size: $fs-10 !important; }
.fs-12{ font-size: $fs-12 !important; }
.fs-14{ font-size: $fs-14 !important; }
.fs-16{ font-size: $fs-16 !important; }
.fs-18{ font-size: $fs-18 !important; }
.fs-20{ font-size: $fs-20 !important; }
.fs-22{ font-size: $fs-22 !important; }
.fs-24{ font-size: $fs-24 !important; }
.fs-26{ font-size: $fs-26 !important; }
.fs-28{ font-size: $fs-28 !important; }
.fs-30{ font-size: $fs-30 !important; }
.fs-32{ font-size: $fs-32 !important; }
.fs-34{ font-size: $fs-34 !important; }
.fs-38{ font-size: $fs-38 !important; }
.fs-40{ font-size: $fs-40 !important; }
.fs-42{ font-size: $fs-42 !important; }
.fs-44{ font-size: $fs-44 !important; }
.fs-52{ font-size: $fs-52 !important; }
.fs-56{ font-size: $fs-56 !important; }
.fs-60{ font-size: $fs-60 !important; }
.fs-62{ font-size: $fs-62 !important; }
.fs-70{ font-size: $fs-70 !important; }
.fs-80{ font-size: $fs-80 !important; }

// Displays

.d-none{ display: none !important; }
.d-lg-none {
  @include media-min992 {
    display: none !important;
  }
}
.d-block { display: block !important; }
.d-lg-block {
  @include media-min992 {
    display: block !important;
  }
}
.d-inline-block{ display: inline-block !important; }
.d-flex{
  display: flex;
}
.d-lg-flex{
  @include media-min992 {
    display: flex !important;
  }
}
.column{
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.align-items-start{
  align-items: flex-start;
}
.align-items-end{
  align-items: flex-end;
}
.align-items-center{
  align-items: center;
}
.align-self-center{
  align-self: center !important;
}
.justify-content-start{
  justify-content: flex-start;
}
.justify-content-end{
  justify-content: flex-end;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-lg-start {
  @media(min-width: 992px) {
    justify-content: flex-start;
  }
}
.justify-content-between{
  justify-content: space-between;
}

//Text transform & aligns

.uppercase{ text-transform: uppercase; }
.lowercase{ text-transform: lowercase; }

.text-left{ text-align: left !important; }
.text-right{ text-align: right !important; }
.text-center{ text-align: center !important; }
.text-lg-left {
  @include media-min992 {
    text-align: left !important;
  }
}

//Aligns

.align-middle{ vertical-align: middle; }
.align-top { vertical-align: top; }
.align-bottom { vertical-align: bottom; }

// Overflow

.overflow{ overflow: auto !important; }

.position-relative{ position: relative; }

// Font Family

.poppins{ font-family: 'Poppins', sans-serif; }
.opensans{ font-family: 'Open Sans', sans-serif; }


//CLASSES ANTIGAS (PRECISA MUDAR)

.padding-menu{
  padding-top: 136px;
  @include media-min992{ padding-top: 144px; }
}

.margin-menu {
  margin-top: 136px;
  @include media-min992 {
    margin-top: 144px;
  }
}

.p-6{ padding: 6px; }

.ml-0 { margin-left: 0 !important; }
.ml-1{ margin-left: 1rem !important; }
.ml-2{ margin-left: 2rem; }
.mr-1{ margin-right: 1rem; }
.mr-2{ margin-right: 2rem; }
.mr-3{ margin-right: 3rem; }

.m-0{ margin: 0 !important; }
.mt-0{ margin-top: 0 !important; }
.mt-1{ margin-top: 1rem; }
.mt-2{ margin-top: 2rem !important;}
.mt-3{ margin-top: 3rem; }
.mt-30{ margin-top: 30px; }
.mt-45{ margin-top: 45px; }
.mt-70{
  margin-top: 45px;
  @include media-min768{ margin-top: 70px; }
}
.mt-100{
  margin-top: 60px;
  @include media-min768{ margin-top: 100px; }
}
.mt-200{margin-top:200px}

.mb-0{ margin-bottom: 0 !important; }
.mb-1{ margin-bottom: 1rem !important; }
.mb-2{ margin-bottom: 2rem !important; }
.mb-3{ margin-bottom: 3rem !important; }
.mb-24{ margin-bottom: 24px; }
.mb-30{ margin-bottom: 30px; }
.mb-40{ margin-bottom: 40px; }
.mb-45{ margin-bottom: 45px; }
.mb-70{
  margin-bottom: 45px;
  @include media-min768{ margin-bottom: 70px; }
}
.mb-100{
  margin-bottom: 60px;
  @include media-min768{ margin-bottom: 100px; }
}
.mx-auto{ margin-left: auto; margin-right: auto; }

.pb-130{
  padding-bottom: 90px;
  @include media-min992{ padding-bottom: 130px; }
}

.pt-0 { padding-top: 0 !important; }

.py-section{
  padding-top: 30px;
  padding-bottom: 30px;

  @include media-min992{
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

// Margins
.padding-menu {
  padding-top: 180px;

  @media(min-width: 992px) {
    padding-top: 205px;
  }
}
.m-0{ margin: 0 !important; }
.m-1{ margin: 0.5rem !important; }
.m-2{ margin: 1rem !important; }
.m-3{ margin: 1.5rem !important; }
.m-4{ margin: 2rem !important; }
.m-5{ margin: 2.5rem !important; }
.m-6{ margin: 3rem !important; }
.m-7{ margin: 3.5rem !important; }
.m-8{ margin: 4rem !important; }
.m-9{ margin: 4.5rem !important; }
.m-10{ margin: 5rem !important; }

.mt-0{ margin-top: 0 !important; }
.mt-1{ margin-top: 0.5rem !important; }
.mt-2{ margin-top: 1rem !important; }
.mt-3{ margin-top: 1.5rem !important; }
.mt-4{ margin-top: 2rem !important; }
.mt-5{ margin-top: 2.5rem !important; }
.mt-6{ margin-top: 3rem !important; }
.mt-7{ margin-top: 3.5rem !important; }
.mt-8{ margin-top: 4rem !important; }
.mt-9{ margin-top: 4.5rem !important; }
.mt-10{ margin-top: 5rem !important; }
.mt-auto{ margin-top: auto !important; }

.ml-0{ margin-left: 0 !important; }
.ml-1{ margin-left: 0.5rem !important; }
.ml-2{ margin-left: 1rem !important; }
.ml-3{ margin-left: 1.5rem !important; }
.ml-4{ margin-left: 2rem !important; }
.ml-5{ margin-left: 2.5rem !important; }
.ml-6{ margin-left: 3rem !important; }
.ml-7{ margin-left: 3.5rem !important; }
.ml-8{ margin-left: 4rem !important; }
.ml-9{ margin-left: 4.5rem !important; }
.ml-10{ margin-left: 5rem !important; }
.ml-auto{ margin-left: auto !important; }




.mb-0{ margin-bottom: 0 !important; }
.mb-1{ margin-bottom: 0.5rem !important; }
.mb-2{ margin-bottom: 1rem !important; }
.mb-3{ margin-bottom: 1.5rem !important; }
.mb-4{ margin-bottom: 2rem !important; }
.mb-5{ margin-bottom: 2.5rem !important; }
.mb-6{ margin-bottom: 3rem !important; }
.mb-7{ margin-bottom: 3.5rem !important; }
.mb-8{ margin-bottom: 4rem !important; }
.mb-9{ margin-bottom: 4.5rem !important; }
.mb-10{ margin-bottom: 5rem !important; }
.mb-auto{ margin-bottom: auto !important; }

.mr-0{ margin-right: 0 !important; }
.mr-1{ margin-right: 0.5rem !important; }
.mr-2{ margin-right: 1rem !important; }
.mr-3{ margin-right: 1.5rem !important; }
.mr-4{ margin-right: 2rem !important; }
.mr-5{ margin-right: 2.5rem !important; }
.mr-6{ margin-right: 3rem !important; }
.mr-7{ margin-right: 3.5rem !important; }
.mr-8{ margin-right: 4rem !important; }
.mr-9{ margin-right: 4.5rem !important; }
.mr-10{ margin-right: 5rem !important; }
.mr-auto{ margin-right: auto !important; }

.my-0{ margin-top: 0; margin-bottom: 0 !important; }
.my-1{ margin-top: 0.5rem; margin-bottom: 0.5rem !important; }
.my-2{ margin-top: 1rem; margin-bottom: 1rem !important; }
.my-3{ margin-top: 1.5rem; margin-bottom: 1.5rem !important; }
.my-4{ margin-top: 2rem; margin-bottom: 2rem !important; }
.my-5{ margin-top: 2.5rem; margin-bottom: 2.5rem !important; }
.my-6{ margin-top: 3rem; margin-bottom: 3rem !important; }
.my-7{ margin-top: 3.5rem; margin-bottom: 3.5rem !important; }
.my-8{ margin-top: 4rem; margin-bottom: 4rem !important; }
.my-9{ margin-top: 4.5rem; margin-bottom: 4.5rem !important; }
.my-10{ margin-top: 5rem; margin-bottom: 5rem !important; }
.my-auto{ margin-top: auto; margin-bottom: auto !important; }

.mx-0{ margin-left: 0; margin-right: 0 !important; }
.mx-1{ margin-left: 0.5rem; margin-right: 0.5rem !important; }
.mx-2{ margin-left: 1rem; margin-right: 1rem !important; }
.mx-3{ margin-left: 1.5rem; margin-right: 1.5rem !important; }
.mx-4{ margin-left: 2rem; margin-right: 2rem !important; }
.mx-5{ margin-left: 2.5rem; margin-right: 2.5rem !important; }
.mx-6{ margin-left: 3rem; margin-right: 3rem !important; }
.mx-7{ margin-left: 3.5rem; margin-right: 3.5rem !important; }
.mx-8{ margin-left: 4rem; margin-right: 4rem !important; }
.mx-9{ margin-left: 4.5rem; margin-right: 4.5rem !important; }
.mx-10{ margin-left: 5rem; margin-right: 5rem !important; }
.mx-auto{ margin-left: auto; margin-right: auto !important; }

// Paddings
.pt-0{ padding-top: 0 !important; }
.pt-1{ padding-top: 0.5rem !important; }
.pt-2{ padding-top: 1rem !important; }
.pt-3{ padding-top: 1.5rem !important; }
.pt-4{ padding-top: 2rem !important; }
.pt-5{ padding-top: 2.5rem !important; }
.pt-6{ padding-top: 3rem !important; }
.pt-7{ padding-top: 3.5rem !important; }
.pt-8{ padding-top: 4rem !important; }
.pt-9{ padding-top: 4.5rem !important; }
.pt-10{ padding-top: 5rem !important; }

.pl-0{ padding-left: 0 !important; }
.pl-1{ padding-left: 0.5rem !important; }
.pl-2{ padding-left: 1rem !important; }
.pl-3{ padding-left: 1.5rem !important; }
.pl-4{ padding-left: 2rem !important; }
.pl-5{ padding-left: 2.5rem !important; }
.pl-6{ padding-left: 3rem !important; }
.pl-7{ padding-left: 3.5rem !important; }
.pl-8{ padding-left: 4rem !important; }
.pl-9{ padding-left: 4.5rem !important; }
.pl-10{ padding-left: 5rem !important; }

.pb-0{ padding-bottom: 0 !important; }
.pb-1{ padding-bottom: 0.5rem !important; }
.pb-2{ padding-bottom: 1rem !important; }
.pb-3{ padding-bottom: 1.5rem !important; }
.pb-4{ padding-bottom: 2rem !important; }
.pb-5{ padding-bottom: 2.5rem !important; }
.pb-6{ padding-bottom: 3rem !important; }
.pb-7{ padding-bottom: 3.5rem !important; }
.pb-8{ padding-bottom: 4rem !important; }
.pb-9{ padding-bottom: 4.5rem !important; }
.pb-10{ padding-bottom: 5rem !important; }

.pr-0{ padding-right: 0 !important; }
.pr-1{ padding-right: 0.5rem !important; }
.pr-2{ padding-right: 1rem !important; }
.pr-3{ padding-right: 1.5rem !important; }
.pr-4{ padding-right: 2rem !important; }
.pr-5{ padding-right: 2.5rem !important; }
.pr-6{ padding-right: 3rem !important; }
.pr-7{ padding-right: 3.5rem !important; }
.pr-8{ padding-right: 4rem !important; }
.pr-9{ padding-right: 4.5rem !important; }
.pr-10{ padding-right: 5rem !important; }

.py-0{ padding-top: 0 !important; padding-bottom: 0 !important; }
.py-1{ padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
.py-2{ padding-top: 1rem !important; padding-bottom: 1rem !important; }
.py-3{ padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
.py-4{ padding-top: 2rem !important; padding-bottom: 2rem !important; }
.py-5{ padding-top: 2.5rem !important; padding-bottom: 2.5rem !important; }
.py-6{ padding-top: 3rem !important; padding-bottom: 3rem !important; }
.py-7{ padding-top: 3.5rem !important; padding-bottom: 3.5rem !important; }
.py-8{ padding-top: 4rem !important; padding-bottom: 4rem !important; }
.py-9{ padding-top: 4.5rem !important; padding-bottom: 4.5rem !important; }
.py-10{ padding-top: 5rem !important; padding-bottom: 5rem !important; }

.px-0{ padding-left: 0; padding-right: 0 !important; }
.px-1{ padding-left: 0.5rem !important; padding-right: 0.5rem !important; }
.px-2{ padding-left: 1rem !important; padding-right: 1rem !important; }
.px-3{ padding-left: 1.5rem !important; padding-right: 1.5rem !important; }
.px-4{ padding-left: 2rem !important; padding-right: 2rem !important; }
.px-5{ padding-left: 2.5rem !important; padding-right: 2.5rem !important; }
.px-6{ padding-left: 3rem !important; padding-right: 3rem !important; }
.px-7{ padding-left: 3.5rem !important; padding-right: 3.5rem !important; }
.px-8{ padding-left: 4rem !important; padding-right: 4rem !important; }
.px-9{ padding-left: 4.5rem !important; padding-right: 4.5rem !important; }
.px-10{ padding-left: 5rem !important; padding-right: 5rem !important; }

//Width

.w-100 {width: 100% !important;}
.w-40{ width: 40%; }
.w-50{ width: 50%; }
.w-65{ width: 65%; }
.w-75{ width: 75%; }
.w-80{ width: 80%; }

//Border Radius
.radius-0 {
  border-radius: 0 !important;
}

.text-lg-start {
  @media(min-width: 992px) {
    text-align: start !important;
  }
}
