// Css raiz do projeto

/*!
 * Brasileirão - v2
 * Desenvolvido por Fattoria em: 03/03/2022
 */

// Libs
@import "../sassLibs/fontawesome/font-awesome";
@import "../sassLibs/_bootstrap";

// Config
@import "config/_variaveis";

// Tipografia
@import "_tipografia";

// Geral
@import "geral/_medias";
@import "geral/_reset";
@import "geral/_geral";
@import "geral/_utilidades";

// Componentes
@import "componentes/_badges.scss";
@import "componentes/_banners";
@import "componentes/_btns";
@import "componentes/_cards.scss";
@import "componentes/_carrosseis";
@import "componentes/_footer";
@import "componentes/_form";
@import "componentes/_header";
@import "componentes/_jogo.scss";
@import "componentes/_tables.scss";
@import "componentes/_titulos";
@import "componentes/_modal";
@import "componentes/_box";

// Páginas
@import "paginas/_novo-badge";
@import "paginas/_meus-badges";
@import "paginas/_ligas";
@import "paginas/_palpites";
@import "paginas/_craque-da-galera";



 


































